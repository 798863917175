import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import MenuButton from "./button/menuButton";
import Drawer from "./drawer";
import {Link} from "react-router-dom";
import AccountIcon from "./icon/accountIcon";
// import BookNowIcon from "./icon/bookNowIcon";
import CartIcon from "./icon/cartIcon";
import YpIcon from "./icon/yelpIcon";
import TwtrIcon from "./icon/twitterIcon";
import InstaIcon from "./icon/instagramIcon";
import FbIcon from "./icon/facebookIcon";
import FadeButton from "./button/fadeButton";
import {Badge, Grid, Typography} from "@mui/material";
import { styled } from '@mui/material/styles';
import * as global from "./custom";
import {useCart} from "react-use-cart";
import ProfileModal from "./modals/profileModal";

const pages = global.custom.pages;
const iconSize = global.custom.icon.main

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 14,
        top: 2,
    },
}));

function CartAndBadge(totalItems) {
    if(totalItems === 0){
        return <CartIcon size={iconSize} />
    } else if(totalItems > 0) {
        return <>
            <StyledBadge badgeContent={totalItems} color="tertiary">
                <CartIcon size={iconSize} />
            </StyledBadge>
        </>
    }
}

const ResponsiveAppBar = (props) => {

    const {
        totalItems,
    } = useCart();

    const welcomeMessage = (props.welcome ? "Hi, " + props.welcome + "!" : "")
    const classes = {
        logo: {
            margin: "auto",
            textAlign: "center",
            maxWidth: "100px",
            maxHeight: "100px"
        },
        logoHorizontallyCenter: {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
        }
    };

    return (
        <AppBar position="static" color="secondary"
                sx={{
                    boxShadow: 0,
                    minWidth: 300,
                    py: 1
        }}>
            <Container maxWidth="100%">

                <Toolbar disableGutters>

                    {/* Desktop & Tablet header buttons */}
                    <Grid container spacing={2} sx={{ display: { xxs: 'none', xs: 'none' } }}>
                        <Grid item width="26%">
                            <Drawer />
                        </Grid>
                        <Grid item sx={{ mt: 2.8 }}>
                            {/*<BookNowIcon size={iconSize} />*/}
                            <ProfileModal buttonFor="headerMenu" bookWith="" iconSize={iconSize} />
                        </Grid>
                        <Grid item sx={{ mt: 2.8 }}>
                            <AccountIcon size={iconSize} />
                        </Grid>
                        <Grid item sx={{ mt: 2.8 }}>
                            {CartAndBadge(totalItems)}
                        </Grid>
                    </Grid>

                    {/* Mobile drawer & cart buttons - very small (xxs) */}
                    <Grid container
                          spacing={2}
                          direction="row"
                          width="100%"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ display: { xxs: 'flex', xs: 'none', md: 'none' } }}>
                        <Grid item>
                            <Drawer />
                        </Grid>
                        <Grid item >
                            {CartAndBadge(totalItems)}
                        </Grid>
                    </Grid>

                    {/* Mobile drawer & cart buttons - small (sm) */}
                    <Grid container
                          spacing={2}
                          direction="row"
                          width="100%"
                          alignItems="center"
                          justifyContent="flex-start"
                          sx={{ display: { xxs: 'none', xs: 'flex', sm: 'flex', md: 'none' } }}>
                        <Grid item>
                            <Drawer />
                        </Grid>
                        <Grid item style={{ position: "relative", left: "-20px" }}>
                            {/*<BookNowIcon size={iconSize} />*/}
                            <ProfileModal buttonFor="headerMenu" bookWith="" iconSize={iconSize} />
                        </Grid>
                    </Grid>

                    <Grid container
                          spacing={2}
                          direction="row"
                          width="100%"
                          alignItems="center"
                          justifyContent="flex-end"
                          sx={{ display: { xxs: 'none', xs: 'flex', sm: 'flex', md: 'none' } }}>
                        <Grid item>
                            <AccountIcon size={iconSize} />
                        </Grid>
                        <Grid item>
                            {CartAndBadge(totalItems)}
                        </Grid>
                    </Grid>

                    {/* Desktop Menu Buttons */}
                    <Box sx={{ flexDirection: 'row', display: { xxs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <MenuButton
                                key={page}
                                sx={{ my: 4, display: { xxs: 'none', md: 'flex' } }}
                                component={Link}
                                to={'/' && page}
                            >
                                {page}
                            </MenuButton>
                        ))}
                    </Box>

                    {/* Logo */}
                    <Box sx={{ flexDirection: 'row', flexGrow: 1, zIndex: 1000 }}>
                        <FadeButton component={Link} to={'/'}
                                    style={ classes.logoHorizontallyCenter }
                        >
                            <img src={"../AftonBluLogo.png"} style={classes.logo}
                                 width={"200px"}
                                 alt="logo"
                            />
                        </FadeButton>
                    </Box>

                    <Grid container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          sx={{
                              display: { xxs: 'none', md: 'flex' }
                        }}
                    >
                        <Grid item width={140}>
                            <ProfileModal buttonFor="headerMenuWithText" bookWith="" iconSize={iconSize} />
                        </Grid>
                        <Grid item width={120}>
                            <AccountIcon size={iconSize} />
                        </Grid>
                        <Grid item width={120}>
                            {CartAndBadge(totalItems)}
                        </Grid>
                        <Grid item width={50}>
                            <YpIcon size={iconSize}/>
                        </Grid>
                        <Grid item width={50}>
                            <TwtrIcon size={iconSize} />
                        </Grid>
                        <Grid item width={50}>
                            <InstaIcon size={iconSize} link={'https://www.instagram.com/afton_blu/?hl=en'}/>
                        </Grid>
                        <Grid item width={50}>
                            <FbIcon size={iconSize} link={'https://www.facebook.com/people/Afton_Blu/100073899301024/'} />
                        </Grid>
                    </Grid>

                    {/* Welcome Message */}
                    <Box sx={{ flexDirection: 'row-reverse'
                        , position: 'absolute'
                        , right: 0
                        , top: 0
                        , paddingRight: 1
                    }}>
                        <Typography variant="h4">
                            {welcomeMessage}
                        </Typography>
                    </Box>

                    <Box sx={{ flexDirection: 'row'
                        , position: 'absolute'
                        , left: 0
                        , top: 0
                        , paddingLeft: 1
                    }}>
                        <Typography variant="h4">
                            <FadeButton component={Link} to='/join'>Join Our Team</FadeButton>
                        </Typography>
                    </Box>

                </Toolbar>

            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
