import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";
import PartialBlueUnderlinedButton from "../components/button/partialBlueUnderlinedTitle";
import {styled} from "@mui/material/styles";
import {custom} from "../components/custom";
import ProfileModal from "../components/modals/profileModal";

const textColor = custom.color.about

const styles = {
    paperContainer: {
        height: 300,
        width: "100%",
        backgroundImage: `url(${"../backgrounds/about-bg-lt.jpeg"})`,
        backgroundAttachment: 'scroll',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: 3,
    }
};

const TitleBreakPoints = styled('span')(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        fontSize: 34,
        paddingBottom: 0,
        color: custom.color.about,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 36,
        paddingBottom: 0,
        color: custom.color.about,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: 38,
        paddingBottom: 0,
        color: custom.color.about,
    },
}));

function About() {
    return (
        <Box style={styles.paperContainer} height="100%" sx={{ paddingTop: '9%', paddingBottom: {xs: '480px', sm: '320px'} }} // paddingBottom is for page text spacing
             display="block">
            <Box
                sx={{
                    display: 'flex'
                    , justifyContent: 'center'
                    , backgroundColor: '#fff'
                    , marginLeft: '5%'
                    , marginRight: '5%'
                    , marginBottom: '5%'
                }}
                display="block"
            >
            <Grid container spacing={2} direction="row">

                <Grid item xxs={12} xs={12} sm={8} md={8} lg={9} xl={9}
                    sx={{ marginLeft: 5, paddingRight: 5, paddingBottom: 2  }}
                >

                    <PartialBlueUnderlinedButton
                        alt="About Us"
                        disabled
                        sx={{
                            letterSpacing: 12,
                            paddingLeft: 0,
                            paddingBottom: 0,
                        }}
                    >
                        <TitleBreakPoints>
                            about us
                        </TitleBreakPoints>
                    </PartialBlueUnderlinedButton>
                    <Typography sx={{ color: textColor, padding: 1 }}>
                        Located in Columbus, Ohio Afton Blu is an open concept booth rental salon.
                        We would love the opportunity to let our family of stylists and professionals empower you with beauty and confidence.
                        Creating long lasting client relationships is very important to us here at Afton Blu.
                        This enables us to evolve your style with confidence and trust.
                        Each member of our salon family is independent and makes their own schedule.
                            <br /><br />
                        Please take a moment to browse <a href="/team">stylists profiles</a> by clicking each stylist picture and
                        reach out to them directly via the provided contact information or social media link, or click
                        the <ProfileModal buttonFor="plainText" bookWith="" iconSize={20}/> button to make your own reservation.
                    </Typography>

                </Grid>

                <Grid item sm={2} md={3} lg={2} xl={2}
                      sx={{
                          display: { xxs: 'none', xs: 'none', sm: 'flex', md: 'flex' }
                          , justifyContent: "center"
                      }}>

                    <img src="./AftonBluLogo.png"
                         style={{
                             display: "flex",
                             justifyContent: 'center',
                             width: "240px",
                             height: "240px",
                             paddingRight: "20px",
                             paddingLeft: "30px"
                         }}
                         alt=""
                    />

                </Grid>

            </Grid>
            </Box>
        </Box>

    )
};

export default About;
