import Api from "../callApi/getApi";
import {Grid, Typography} from "@mui/material";
import {custom} from "../custom";
// import PriceDeserialize from "../parse/priceDeserialize";
import ProductImage from "../parse/productImage";
import AddToCartButton from "../button/addToCartButton";
import { useCart } from "react-use-cart";
import PriceDecimalize from "./priceDecimalize";
// import Button from "@mui/material/Button";

export default function ProductItems () {
    const { ret, isLoading, isError } = Api("/api/products?type=ITEM")

    const plusColor = custom.color.productPlus
    const textColor = custom.color.contact
    const { getItem, removeItem, addItem } = useCart();

    if (isLoading) return <Typography variant="h2" sx={{paddingLeft: 2}}>loading...</Typography> // <Spinner />
    if (isError) return <Typography variant="h2" sx={{paddingLeft: 2}}>data load error...</Typography> // <Error />

    return <>
        {ret.map((product) => (
            <Grid item
                  key={product.id}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="baseline"
                  sx={{ border: 0, padding: 0
                      , marginTop: 3
                      , marginLeft: 1
                      , marginRight: 1
                      , maxWidth: {xxs: "100%", xs: "160px", sm: "160px", md: "160px", lg: "160px" }
                      // , maxWidth: "150px"
                  }}>

                    <Grid container
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                    >

                        <Grid item xxs={12} xs={12} sm={12} justifyContent="center" display="flex" sx={{ paddingBottom: { xxs: 1, xs: 0 } }}>
                            <ProductImage imgId={product.image}/>
                        </Grid>

                        <Grid container>
                            <Grid item xxs={12} xs={9} sm={9} sx={{ paddingLeft: 0, paddingRight: 1 }}>
                                <Typography
                                    sx={{ color: textColor, height: {xxs: "100%", xs: "80px"} }}
                                    fontSize={12}
                                    fontWeight={500}
                                    width="100%"
                                >
                                    {product.name}
                                </Typography>
                            </Grid>
                            <Grid item xxs={12} xs={3} sm={3} sx={{ paddingLeft: 0, paddingRight: 0 }}>
                                <Grid container
                                      display="flex"
                                      justifyContent="flex-end"
                                      alignItems="baseline"
                                >
                                    <Grid item sx={{ fontWeight: 'bold', fontSize: 14}}>
                                        <Typography
                                            sx={{ color: textColor }}
                                            fontSize={13}
                                            fontWeight={900}
                                            width="100%"
                                        >
                                            {/*{product.currency} */}
                                            ${PriceDecimalize(product.price)}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ paddingTop: 1, display: {xxs: 'flex', sm: 'flex'} }}>
                                        <AddToCartButton
                                            size={20}
                                            color={plusColor}
                                            specs={product}
                                            icon={( getItem(product.id) ? 'minus' : 'plus' )}
                                            onClick={( getItem(product.id) ? () => removeItem(product.id) : () => addItem(product) )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            </Grid>

        ))}
    </>
}

