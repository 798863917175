import Redirect from "./redirect";
import setLocalStorage from "../localStorage/setLocalStorage"

export default function LocalStorageSave(ret, redirectVar){
    const redirect = redirectVar
    setLocalStorage("customerId", ret.customers[0].id);
    setLocalStorage("firstName", ret.customers[0].givenName);
    if(redirect && redirect.length > 0 && redirect !== 'undefined' && redirect !== undefined) {
        Redirect(redirect);
    }
}
