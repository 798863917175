import PartialBlueUnderlinedTitle from "../components/button/partialBlueUnderlinedTitle";
import {custom} from "../components/custom";
import {Grid} from "@mui/material";
import ProductItems from "../components/parse/productItems";

const textColor = custom.color.contact

function Shop() {
    return (
        <>

            <PartialBlueUnderlinedTitle
                disabled
                alt="Shop AftonBlu Products"
                sx={{ color: textColor
                    , fontSize: {xxs: 28, xs: 28, sm: 28, md: 30 }
                    , fontWeight: 300
                    , width: {xxs: "360px", xs: "360px", sm: "360px", md: "410px" }
                    , letterSpacing: {xxs: 10, xs: 10, sm: 10, md: 12 }
                    , paddingTop: 4 }}
            >
                our products
            </PartialBlueUnderlinedTitle>

            <Grid container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ paddingLeft: {xxs: 1, xs: 1, sm: 4, md: 4 }
                      , paddingRight: {xxs: 1, xs: 1, sm: 4, md: 4 }
                      , display: { xxs: 'flex', md: 'flex' } }}
            >

                {/* List all product items from Square */}
                <ProductItems/>

            </Grid>
        </>

    )
};

export default Shop;
