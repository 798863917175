import { AiOutlineShoppingCart } from 'react-icons/ai';
import FadeButton from "../button/fadeButton";
import {Link} from "react-router-dom";

export default function CartIcon(props) {

    const size = props.size
    const color = props.color

    return (
        <FadeButton component={Link} to={'/cart'}>
            <AiOutlineShoppingCart sx={{pt: 0.5}} size={size} style={{ color: color, width: "60px" }} />
        </FadeButton>
    )

}
