import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { keyframes } from "@emotion/react";

    const enterKeyframe = keyframes`
  0% {
    transform: scale(0);
    opacity: .8;
  }
  100% {
    transform: scale(1);
    opacity: .8;
  }
`;

// h1:before{
//     content: "";
//     position: absolute;
//     width: 50%;
//     height: 1px;
//     bottom: 0;
//     left: 25%;
//     border-bottom: 1px solid red;
// }

const BlueUnderlinedButton = styled(Button)`
  text-decoration: underline;
  text-decoration-color: #b8ddf3;
  text-decoration-thickness: 4px;
  text-transform: lowercase;
  font-size: 28px;
  letter-spacing: 4px;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
  height: 38px;
  min-width: 150px;
  &:hover {
    background-color: #b8ddf3;
    text-decoration: underline;
    text-decoration-color: #b8ddf3;
  }
  && .MuiTouchRipple-child {
    background-image: linear-gradient(to right, #cccccc, #000000);
  }
  && .MuiTouchRipple-rippleVisible {
    opacity: 1;
    animation-name: ${enterKeyframe};
    animation-duration: 550ms;
    animation-timing-function: ${({ theme }) =>
        theme.transitions.easing.easeInOut};
  }
`;
export default BlueUnderlinedButton;
