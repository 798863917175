import { FiEdit3 } from 'react-icons/fi';
import FadeButton from "../button/fadeButton";

export default function EditIcon(props) {

    const { size, color, editFunction } = props

    return (
        <FadeButton sx={{ width: 20, backgroundColor: color}} onClick={() => editFunction()}>
            <FiEdit3 sx={{pt: 0.5 }} size={size} />edit
        </FadeButton>
    )

}
