import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import Redirect from "../helper/redirect"
import SignOut from "../auth/signout";

export default function Confirm(props) {
    let {message,showConfirmDelete} = props
    const [open, setOpen] = useState(false);
    const [doDelete, setDoDelete] = useState(false);
    const styles = { color: '#000000', backgroundColor: '#ffffff'}
    // const [activateDelete, setActivateDelete] = useState(false);


    useEffect( () => {
        setOpen(showConfirmDelete);
    },[showConfirmDelete])



    function handleAcceptButton() {

        setDoDelete(true)
        setOpen(false)
    }

    function handleCancelButton() {
        setOpen(false)
        Redirect('/account');
    }


    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"
                    sx={styles}
                >
                        Please Confirm
                </DialogTitle>
                <DialogContent
                    sx={styles}
                >

                    <Grid container direction="column" display="flex">
                        {/*<Typography variant="p" sx={{paddingBottom: 2}}>*/}
                        {/*    Confirm*/}
                        {/*</Typography>*/}
                        <Typography variant="p">
                            {message}
                        </Typography>
                    </Grid>

                </DialogContent>
                <DialogActions
                    sx={styles}
                >
                    <Button onClick={handleAcceptButton}>Sign Out</Button>
                    <Button onClick={handleCancelButton}>Cancel</Button>
                </DialogActions>
            </Dialog>
            {/* Perform Delete */}
            {/*<AccountDelete doIt={doDelete}/>*/}
            <SignOut doIt={doDelete} />
        </div>
    );
}
