import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const BlueUnderlinedButton = styled(Button)`
  text-transform: lowercase;
  background-color: transparent;
  color: #fff;
  &:before {
    content: "";
    position: absolute;
    bottom: 6px; // 25px;
    left: 83%;
    width: 7%;
    height: 1px;
    border-bottom: 4px solid #b8ddf3;
  }
`;
export default BlueUnderlinedButton;
