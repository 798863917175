export default function PriceDecimalize(rawNumber){
    if(typeof rawNumber === "string"){
        return (parseInt(rawNumber)/100).toFixed(2)
    } else if(typeof rawNumber === "number"  && rawNumber > 0) {
        return rawNumber.toFixed(2)/100
    } else if(rawNumber <= 0 ){
        return 0
    } else {
        return 0
    }
}
