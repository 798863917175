export default function cleanText(incomingText,type) {
    let outgoingText = ''
    if(!incomingText || incomingText.length === 0){
        return ''
    }
    else if(type === 'email'){
        const mailFormat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        outgoingText = incomingText.match(mailFormat);
        //console.log("outgoingText: " + outgoingText)
    } else if(type === 'text') {
        outgoingText = incomingText.replace(/[^\w\s!?+.]/g, '');
    } else if(type === 'phone') {
        outgoingText = incomingText.replace(/\D/g,'');
        //console.log("outgoingText.substring(0,1): " + outgoingText.substring(0,1))
        // if(outgoingText.substring(0,1) === '1'){
        //
        // }
        return outgoingText;
        // if(outgoingText.substring(0,1) === '1') {
        //     outgoingText.slice(2, outgoingText.length)
        // }
        // } else if(outgoingText.charAt(0) === '+'){
        //     outgoingText.slice(1,outgoingText.length)
        // } else if(outgoingText.charAt(0) === '1') {
        //     outgoingText.slice(1, outgoingText.length)
        // }
    }
    return outgoingText;
}
