import {Grid, TextField, Typography} from "@mui/material";
import PartialBlueUnderlinedTitle from "../components/button/partialBlueUnderlinedTitle";
import Box from "@mui/material/Box";
import BlueUnderlinedButton from "../components/button/blueUnderlinedButton";
import {custom} from "../components/custom";
import {useEffect, useState} from "react";
import CleanText from "../components/helper/clean"
import options from "../components/callApi/corsApi";

const textColor = custom.color.contact
const bgColor = custom.background.contact
const telephoneNumber = "(" + custom.phone.areaCode + ") " + custom.phone.number
const address1 = custom.address.number + " " + custom.address.street
const address2 = custom.address.city + ", " + custom.address.state + " " + custom.address.zip
const mapLink = 'https://goo.gl/maps/PBmtdaReMqcms682A'

const findUsStyle = {
    paperContainer: {
        backgroundImage: `url(./find-us-AftonBlu.png)`,
        backgroundRepeat: false,
        height: 722,
        minWidth: 300,
        maxWidth: 439
    }
};

const contactUsStyle = {
    paperContainer: {
        backgroundImage: `url(./backgrounds/contact-bg-lt.jpeg)`,
        backgroundRepeat: false,
        height: 722,
        minWidth: 300,
        maxWidth: 439
    }
};

// sx={{ display: { xxs: 'none', xs: 'none' } }}

const getInTouchStyle = {
    paperContainer: {
        backgroundRepeat: false,
        minWidth: 300,
        maxWidth: 439
    }
};

function Contact(props) {

    // const queryString = window.location.search;
    // const urlParams = useMemo(() => {
    //     const getQueryString = () => new URLSearchParams(queryString);
    //     console.log("QueryString: " + getQueryString())
    //     return getQueryString();
    // }, [queryString]);

    const [joinProp, setJoinProp] = useState(props.join);

    const [join, setJoin] = useState("flex");
    const [messageTitle, setMessageTitle] = useState("get in touch");
    const [message, setMessage] = useState("I would like to join your team! ");
    const [titleLetterSpacing, setTitleLetterSpacing] = useState(5);

    useEffect(() => {
        if (joinProp === 1){
            setJoin("none")
            setMessageTitle("join our team")
            setTitleLetterSpacing(4)
        } else {
            setMessage('')
            setJoinProp(0)
        }
    },[joinProp])

    //   Form values
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("AftonBlu - Website Contact Page");

    //   Setting button text
    const [buttonText, setButtonText] = useState("send");

    //   Response Messages
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);

    const handleValidation = () => {
        let isValid = true;
        let retMessage = "";

        if (fullName.length <= 0) {
            isValid = false;
            retMessage = "Please enter a valid name.";
        }else if (email.length <= 0 || !CleanText(email,"email")) {
            isValid = false;
            retMessage = "Please enter a valid email address.";
        }else if (subject.length <= 0 || !CleanText(subject,"text")) {
            isValid = false;
            retMessage = "Please enter a valid subject.";
        }else if (message.length <= 0 || !CleanText(message,"text")) {
            isValid = false;
            retMessage = "Please enter a valid message.";
        }

        //console.log("isValid: ", isValid);
        if(retMessage.length > 0){
            alert(retMessage);
        }
        return isValid

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let isValidForm = handleValidation();

        if (isValidForm) {
            setButtonText("sending...");
            const res = await fetch("/api/sendgrid", {
                body: JSON.stringify({
                    email: email,
                    fullName: CleanText(fullName,"text"),
                    subject: CleanText(subject,"text"),
                    message: CleanText(message,"text"),
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                options
            });

            const { error } = await res.json();
            if (error) {
                console.log(error);
                setShowSuccessMessage(false);
                setShowFailureMessage(true);
                setButtonText("send");

                // Reset form fields
                setFullName("");
                setEmail("");
                setMessage("");
                setSubject("");
                return;
            }
            setShowSuccessMessage(true);
            setShowFailureMessage(false);
            setButtonText("send");
            // Reset form fields
            setFullName("");
            setEmail("");
            setMessage("");
            setSubject("");
        }
        //console.log(fullName, email, subject, message);
    };


    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="stretch"
        >
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
            >
                <Grid item xs={12}
                      style={findUsStyle.paperContainer}
                      justifyContent="center"
                      alignItems="flex-start"
                      display={join}
                >

                    <Grid container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                    >
                        <Grid item>
                            <PartialBlueUnderlinedTitle
                                disabled
                                alt="Find Us"
                                sx={{ color: textColor
                                    , fontSize: 32
                                    , fontWeight: 300
                                    , width: "196px"
                                    , letterSpacing: 11.5
                                    , paddingTop: 4 }}
                            >
                                find us
                            </PartialBlueUnderlinedTitle>
                        </Grid>
                        <Grid item sx={{ paddingTop: 12 }}>
                            <BlueUnderlinedButton href={mapLink} target="_blank" sx={{ backgroundColor: "#ffffff", padding: 6 }}>Open Map</BlueUnderlinedButton>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12}
                      style={contactUsStyle.paperContainer}
                      justifyContent="center"
                      alignItems="flex-start"
                      display={join}
                >

                    <Grid container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                    >
                        <Grid item>
                            <PartialBlueUnderlinedTitle
                                alt="Contact Us"
                                disabled
                                sx={{ color: "black"
                                    , fontSize: 32
                                    , fontWeight: 300
                                    , width: "260px"
                                    , letterSpacing: 6.2
                                    , paddingTop: 4 }}
                            >
                                contact us
                            </PartialBlueUnderlinedTitle>
                        </Grid>
                        <Grid item
                              display="block"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ border: 1, padding: 2, marginTop: 16, backgroundColor: bgColor }}>
                                <Typography
                                    width="100%"
                                    variant="h2"
                                    fontWeight={800}
                                    style={{margin: 0, textAlign: "center"}}
                                >
                                        Telephone
                                </Typography>
                                <Typography
                                    width="100%"
                                    variant="p"
                                    style={{margin: 0, paddingTop: 4}}
                                >
                                    <a href={`tel:${telephoneNumber}`}>{telephoneNumber}</a>
                                </Typography>
                        </Grid>
                        <Grid item
                              display="block"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ border: 1, padding: 2, marginTop: 16, backgroundColor: bgColor }}>
                            <Typography
                                width="100%"
                                variant="h2"
                                fontWeight={800}
                                style={{margin: 0, textAlign: "center"}}
                            >
                                Address
                            </Typography>
                            <Typography
                                width="100%"
                                variant="p"
                            >
                                {address1} <br />
                            </Typography>
                            <Typography
                                width="100%"
                                variant="p"
                            >
                                {address2}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}
                      style={getInTouchStyle.paperContainer}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{ height: { xs: 500, sm: 500, md: 500, lg: 722 } }}
                >

                    <Grid container
                          direction="column"
                          justifyContent="center"
                          alignItems="stretch"
                    >
                        <Grid item textAlign="center">
                            <PartialBlueUnderlinedTitle
                                alt="Get in Touch"
                                disabled
                                sx={{ color: textColor
                                    , fontSize: 32
                                    , fontWeight: 300
                                    , width: "294px"
                                    , letterSpacing: titleLetterSpacing
                                    , paddingTop: 4 }}
                            >
                                {messageTitle}
                            </PartialBlueUnderlinedTitle>
                        </Grid>

                        {/* FORM SUBMISSION */}
                        <form onSubmit={handleSubmit}>
                            <Grid item>
                                <Box style={{ paddingTop: 40, marginLeft: '5%', marginRight: '5%' }}
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="flex-end" >

                                    <TextField
                                        id="contact-name"
                                        label="Name"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name="name"
                                        value={fullName}
                                        onChange={(e) => {
                                            setFullName(e.target.value);
                                        }}
                                    />

                                </Box>
                            </Grid>
                            <Grid item>
                                <Box style={{ paddingTop: 40, marginLeft: '5%', marginRight: '5%' }}
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="flex-end" >
                                    <TextField id="contact-email"
                                               label="Email"
                                               variant="outlined"
                                               size="small"
                                               fullWidth
                                               name="email"
                                               value={email}
                                               onChange={(e) => {
                                                   setEmail(e.target.value);
                                               }}
                                    />

                                </Box>

                            </Grid>
                            <Grid item>
                                <Box style={{ paddingTop: 40, marginLeft: '5%', marginRight: '5%' }}
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="flex-end" >
                                    <TextField id="contact-message"
                                               label="Message"
                                               variant="outlined"
                                               size="small"
                                               fullWidth
                                               multiline
                                               rows={4}
                                               name="message"
                                               value={message}
                                               onChange={(e) => {
                                                   setMessage(e.target.value);
                                               }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box style={{ paddingTop: 20, marginLeft: '5%', marginRight: '5%' }}
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="stretch" >
                                    <BlueUnderlinedButton
                                        sx={{ minWidth: 100, marginLeft: 1, marginRight: 1}}
                                        type="submit"
                                    >{buttonText}</BlueUnderlinedButton>
                                </Box>
                            </Grid>
                            <Grid item textAlign="center">
                                {/* Response Message */}
                                <Box sx={{ paddingTop: 2 }}>
                                    {showSuccessMessage && (
                                        <Typography variant="p">
                                            Thank you. Your message has been sent.
                                        </Typography>
                                    )}
                                    {showFailureMessage && (
                                        <Typography variant="p">
                                            Oops! Something went wrong. Please try again.
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Contact;
