import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";
import PartialBlueUnderlinedButton from "../components/button/partialBlueUnderlinedTitle";
import {styled} from "@mui/material/styles";
import {custom} from "../components/custom";
import {Link} from "react-router-dom";
const textColor = custom.color.about

const styles = {
    paperContainer: {
        height: 300,
        width: "100%",
        backgroundImage: `url(${"../backgrounds/about-bg-lt.jpeg"})`,
        backgroundAttachment: 'scroll',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: 3,
    }
};

const TitleBreakPoints = styled('span')(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        fontSize: 38,
        paddingBottom: 0,
        color: custom.color.about,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 36,
        paddingBottom: 0,
        color: custom.color.about,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: 34,
        paddingBottom: 0,
        color: custom.color.about,
    },
}));

function Welcome() {
    return (
        <>
            <Box style={styles.paperContainer} height="100%" sx={{ paddingTop: '9%', paddingBottom: {xs: '240px', sm: '160px'} }} // paddingBottom is for page text spacing
                 display="block">
                <Box
                    sx={{
                        display: 'flex'
                        , justifyContent: 'center'
                        , backgroundColor: '#fff'
                        , marginLeft: '5%'
                        , marginRight: '5%'
                        , marginBottom: '5%'
                    }}
                    display="block"
                >
                <Grid container spacing={2} direction="row">


                    <Grid item xxs={12} xs={12} sm={11} md={11} lg={11} xl={11}
                        sx={{ marginLeft: 5, paddingRight: 5, paddingBottom: 2  }}
                    >

                        <PartialBlueUnderlinedButton
                            alt="You are now logged into AftonBlu.com"
                            disabled
                            sx={{
                                letterSpacing: 12,
                                paddingLeft: 0,
                                paddingBottom: 0,
                            }}
                        >
                            <TitleBreakPoints>
                                welcome
                            </TitleBreakPoints>
                        </PartialBlueUnderlinedButton>
                        <Typography sx={{ color: textColor, padding: 1 }}>
                            You are logged in to AftonBlu.com.
                            <br /><br />
                            Now, you have access to <Link to="/team">book a stylist</Link> and <Link to="/shop">purchase hair products</Link>.
                            <br /><br />
                            You can also view your account information on the <Link to="/account">account</Link> page.
                        </Typography>

                    </Grid>



                </Grid>
                </Box>
            </Box>
        </>
    )
};

export default Welcome;
