import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Grid, Typography} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {Link} from "react-router-dom";
import FadeButton from "./button/fadeButton";
import {HiOutlineUserCircle} from "react-icons/hi";
import {AiOutlineCalendar, AiOutlineShoppingCart} from "react-icons/ai";
import TwtrIcon from "./icon/twitterIcon";
import YpIcon from "./icon/yelpIcon";
import InstaIcon from "./icon/instagramIcon";
import FbIcon from "./icon/facebookIcon";

const iconSeparationStyle = {
    paddingLeft: 12, paddingRight: 12
};

export default function SwipeableTemporaryDrawer() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <>
            <Box
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <Grid container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                >
                    <Grid item
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                    >


                            <Grid container
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  direction="column"
                                  sx={{ paddingTop: 2}}
                            >
                                {['Home', 'Team', 'About', 'Contact','Shop'].map((text, index) => (
                                <Grid item key={index} sx={{ color: '#fff', padding: 2 }}>
                                    <FadeButton
                                        component={Link}
                                        to={'/' + text}
                                        sx={{ color: '#fff', fontSize: 32, textTransform: 'uppercase' }}>
                                            {text}
                                    </FadeButton>
                                </Grid>
                                ))}
                                <Grid item sx={{ color: '#fff', paddingTop: 4}}>
                                    <FadeButton
                                        component={Link}
                                        to={'/account'}
                                        sx={{ color: '#fff', fontSize: 32, textTransform: 'lowercase' }}>
                                            <Grid container
                                                direction="row">
                                                <Grid item>
                                                    <Typography sx={{fontSize: 32, paddingRight: 1}}>account</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <HiOutlineUserCircle sx={{pt: 0.5 }} size={50} style={{color: '#fff'}} />
                                                </Grid>
                                            </Grid>
                                    </FadeButton>
                                </Grid>
                                <Grid item sx={{ color: '#fff'}}>
                                    <FadeButton
                                        component={Link}
                                        to={'/cart'}
                                        sx={{ color: '#fff', fontSize: 32, textTransform: 'lowercase' }}>
                                            <Grid container
                                                  direction="row">
                                                <Grid item>
                                                    <Typography sx={{fontSize: 32, paddingRight: 1}}>cart</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <AiOutlineShoppingCart sx={{pt: 0.5 }} size={50} style={{color: '#fff'}} />
                                                </Grid>
                                            </Grid>
                                    </FadeButton>
                                </Grid>
                                <Grid item sx={{ color: '#fff'}}>
                                    <FadeButton
                                        href="/home/open"
                                        sx={{ color: '#fff', fontSize: 32, textTransform: 'lowercase' }}>
                                            <Grid container
                                                  direction="row">
                                                <Grid item>
                                                    <Typography sx={{fontSize: 32, paddingRight: 1}}>book now</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <AiOutlineCalendar sx={{pt: 0.5 }} size={50} style={{color: '#fff'}} />
                                                </Grid>
                                            </Grid>
                                    </FadeButton>
                                </Grid>
                                <Grid item sx={{ color: '#fff', paddingTop: 4}}>
                                    <Grid container direction="row" display='flex' alignItems='center' justifyContent='center'>
                                        <Grid item style={iconSeparationStyle}>
                                            <YpIcon size={34} color='#fff' width="20"/>
                                        </Grid>
                                        <Grid item style={iconSeparationStyle}>
                                            <TwtrIcon size={34} color='#fff' width="20" />
                                        </Grid>
                                        <Grid item style={iconSeparationStyle}>
                                            <InstaIcon size={34} color='#fff' width="20" link={'https://www.instagram.com/afton_blu/?hl=en'} />
                                        </Grid>
                                        <Grid item style={iconSeparationStyle}>
                                            <FbIcon size={34} color='#fff' width="20" link={'https://www.facebook.com/people/Afton_Blu/100073899301024/'} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>

                    </Grid>
                </Grid>
            </Box>
        </>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>

                        <IconButton
                            size="small"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={toggleDrawer(anchor, true)}
                            style={{ height: "83.33px"
                                , width: "83.33px"
                            }}
                        >
                            <MenuIcon sx={{ color: '#000' }} />
                        </IconButton>

                    <SwipeableDrawer
                        sx={{
                            display: { xxs: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
                        }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {/*    This is what is shown in the drawer  */}
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}
