import * as React from 'react';
import Box from "@mui/material/Box";
import {Grid, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import {AiOutlineDown} from 'react-icons/ai';
import * as global from "../components/custom";
import { IconContext } from "react-icons";
import PartialBlueUnderlinedButton from "../components/button/partialBlueUnderlinedButton";
import {styled} from "@mui/material/styles";
import * as stylistObject from "../components/objects/stylistObject";
import ProfileModal from "../components/modals/profileModal";

const iconSize = global.custom.icon.main
const popoverMenuTextColor = global.custom.color.stylists
const stylistCategories = global.custom.stylistCategories

// team.js background
const styles = {
    paperContainer: {
        height: "100%",
        width: "100%",
        backgroundColor: '#000',
        backgroundAttachment: 'scroll',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingTop: 3,
    }
};

const TitleBreakPoints = styled('span')(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        fontSize: 34,
        paddingBottom: 1,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 36,
        paddingBottom: 1,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: 38,
        paddingBottom: 1,
    },
}));


function Team() {

    const [anchorElementUser, setAnchorElementUser] = React.useState(false);
    const [filterValue, setFilterValue] = React.useState('all');

    const stylistProfiles = stylistObject.stylistObject.stylists;
    const profiles = []

    stylistProfiles.forEach((data) => {
        if(data.stylistFilter.includes(filterValue) ) {
            profiles.push(
                <Grid item xs={6} md={4} lg={3} key={data.firstLastName}>
                    <ProfileModal buttonFor="teamPage"
                                  bookNowOpen={false}
                                  bookWith={data.firstLastName}
                                  imgSrc={data.profileOptions[0].stylistImgMain}
                                  showPagination={false}
                    />
                </Grid>
            )
        }
    })

    const handleOpenUserMenu = (event) => {
        setAnchorElementUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElementUser(null);
    };

    const handleFilter = async (e, category) => {
        e.preventDefault();
        setFilterValue(category)
        handleCloseUserMenu()
        // console.log('category: ' + JSON.stringify(category))
        return null
    }

    // useEffect(() => {
    //     console.log("filterValue: " + filterValue)
    // },[filterValue])

    return (
        <Box style={styles.paperContainer} height="100%">

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    height="100%"
                >
                    <Grid item sx={{ marginLeft: 4, marginTop: 0 }}>

                            <Tooltip title="Filter Stylists">

                                <PartialBlueUnderlinedButton sx={{
                                    letterSpacing: 20,
                                    paddingTop: 0,
                                    marginTop: 0,
                                    paddingBottom: 0,
                                    marginBottom: 1.5,
                                }}
                                onClick={handleOpenUserMenu}
                                >
                                    <IconContext.Provider value={{ color: popoverMenuTextColor }}>
                                        <AiOutlineDown size={iconSize} style={{ paddingTop: 10 }} />
                                    </IconContext.Provider>
                                    <TitleBreakPoints style={{ paddingLeft: 20 }}>
                                        stylists
                                    </TitleBreakPoints>
                                </PartialBlueUnderlinedButton>

                            </Tooltip>
                            <Menu
                                sx={{
                                    mt: '80px',
                                }}
                                id="menu-appbar"
                                anchorEl={anchorElementUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElementUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {stylistCategories.map((category) => (
                                    <MenuItem key={category} value={category} onClick={(e) => {
                                        handleFilter(e, category);
                                    }}>
                                        <Typography sx={{
                                            letterSpacing: 8
                                            , color: popoverMenuTextColor
                                            , paddingLeft: '30px'
                                            , marginRight: '5px'
                                        }}>
                                            {category}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>

                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="flex-start"
                    height="100%"
                >
                        {profiles}
                </Grid>

        </Box>
    )
};

export default Team;
