import { HiOutlineUserCircle } from 'react-icons/hi';
import {Grid} from "@mui/material";
import FadeButton from "../button/fadeButton";
import {Link} from "react-router-dom";

export default function AccountIcon(props) {

    const size = props.size
    const color = props.color

    return (
        <>
            <FadeButton component={Link} to={'/account'} sx={{ display: { xxs: 'none', xs: 'none', md: 'flex' } }}>
                <Grid container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      sx={{ mr: 1 }}>
                    <Grid item
                          sx={{ mr: 0.5, display: { xxs: 'none', xs: 'none', md: 'none', lg: 'flex', xl: 'flex' } }}
                          style={{ color: color }}>
                        account
                    </Grid>
                    <Grid item>
                        <HiOutlineUserCircle sx={{pt: 0.5 }} size={size} style={{ color: color }} />
                    </Grid>
                </Grid>
            </FadeButton>

            <FadeButton component={Link} to={'/account'} sx={{ display: { xxs: 'flex', xs: 'flex', md: 'none' } }}>
                <HiOutlineUserCircle sx={{pt: 0.5 }} size={size} color="primary" />
            </FadeButton>
        </>
    )

}
