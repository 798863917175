import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
    breakpoints: {
        values: {
            xxs: 0,
            xs: 368,
            sm: 650,
            md: 1124,
            lg: 1283,
            xl: 1300,
            xxl: 1536,
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'bordered' },
                    style: {
                        border: `9px dashed red`,

                    },
                },
            ],
        },
        MuiPaper: {
            styleOverrides: { // Stylists menu
                root: {
                    width: '260px',
                    backgroundColor: '#000',
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    paddingLeft: '0',
                    paddingRight: '0',
                    marginLeft: '0',
                    marginRight: '0',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#fff'
                }
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: "#fff",
                    backgroundColor: "#fff",
                }
            }
        },
        MuiFormLabel:{
            styleOverrides: {
                root: {
                    color: "#000",
                }
            }
        },
    },
    palette: {
        action: {
            disabled: '#282828' // Contact Title Color (custom button made into a text header)
        },
        type: 'light',
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#ffffff',
        },
        tertiary: {
            main: '#b8ddf3',
        },
        background: {
            default: '#ffffff',
        },
        text: {
            primary: '#000000',
            secondary: '#737171',
            disabled: '#282828',
            hint: '#000000',
        },
        divider: '#000000',
    },
    typography: {
        fontFamily: 'Raleway',
        fontSize: 14,  // Text INSIDE the buttons (label text)
        fontWeight: 200,
        fontWeightLight: 300,
        fontWeightMedium: 400,
        fontWeightRegular: 500,
        p: {
            fontFamily: 'Raleway',
            fontSize: 18,
            paddingTop: 4,
        },
        body1: {  // Drawer color
          color: "#fff"
        },
        h1: {
            fontSize: 20,
        },
        h2: {
            fontFamily: 'Raleway',
            fontSize: 18,
        },
        h3: {
            fontSize: 28, // Stylist Profile Header
        },
        h4: {
            fontSize: 16, // footer
        },
        stylistPageHeader: {
            fontSize: 24,
            fontFamily: 'Raleway',
        },
        stylistPage1: {
            fontSize: 22,
            fontFamily: 'Raleway',
            fontWeight: 'bold'
        },
        stylistPage2: {
            fontSize: 20,
            fontFamily: 'Raleway',
            fontStyle: 'italic'
        },
        stylistPage3: {
            fontSize: 18,
            fontFamily: 'Raleway',
        },
        stylistPage4: {
            fontSize: 20,
            fontFamily: 'Arial',
            fontWeight: 'bold'
        },
        stylistPage5: {
            fontSize: 18,
            fontFamily: 'Arial',
            fontStyle: 'italic'
        },
        span: {
            fontSize: 16,
            fontFamily: 'Raleway',
        },
        button: {
            fontSize: 18,
        },
        subtitle1: {
            fontSize: 15,
            fontWeight: "bold",
        },
        subtitle2: {
            fontSize: 12,
        },
    },
})
