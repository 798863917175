import decrypt from "./__dec__"

export default function getLocalStorage(param){
    let getEncStoredItem
    let retData = '';
    if(param && param.length > 0){
        getEncStoredItem = localStorage.getItem(param)
    }

    if(getEncStoredItem && getEncStoredItem.length > 0){
        retData = decrypt(getEncStoredItem)
    }
    return retData
}
