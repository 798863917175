import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";
import PartialBlueUnderlinedButton from "../components/button/partialBlueUnderlinedTitle";
import {styled} from "@mui/material/styles";
import {custom} from "../components/custom";
import * as React from "react";
import Redirect from "../components/helper/redirect";
import Button from "@mui/material/Button";

const styles = {
    paperContainer: {
        height: 300,
        width: "100%",
        backgroundImage: `url(${"../backgrounds/about-bg.png"})`,
        backgroundAttachment: 'scroll',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: 3,
    }
};

const TitleBreakPoints = styled('span')(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        fontSize: 38,
        paddingBottom: 0,
        color: custom.color.about,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 36,
        paddingBottom: 0,
        color: custom.color.about,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: 34,
        paddingBottom: 0,
        color: custom.color.about,
    },
}));

function handleCloseButton() {
    // setOpen(false);
    Redirect('/')
}

function Goodbye() {

    // TODO: Use code below to redirect to Auth0 logout - put the code someplace else so that the goodbye page below can render (and not redirect to logout())
    // const { logout } = useAuth0();
    // logout();

    return (
        <>
            <Box style={styles.paperContainer} height="100%" sx={{ paddingTop: '9%', paddingBottom: {xs: '240px', sm: '160px'} }} // paddingBottom is for page text spacing
                 display="block">
                <Box
                    sx={{
                        display: 'flex'
                        , justifyContent: 'center'
                        , backgroundColor: '#fff'
                        , marginLeft: '5%'
                        , marginRight: '5%'
                        , marginBottom: '5%'
                    }}
                    display="block"
                >
                <Grid container spacing={2} direction="row">


                    <Grid item xxs={12} xs={12} sm={11} md={11} lg={11} xl={11}
                        sx={{ marginLeft: 5, paddingRight: 5, paddingBottom: 2  }}
                    >

                        <PartialBlueUnderlinedButton
                            alt="You are now logged out of AftonBlu.com"
                            disabled
                            sx={{
                                letterSpacing: 14,
                                paddingLeft: 0,
                                marginBottom: 1,
                            }}
                        >
                            <TitleBreakPoints>
                                Goodbye
                            </TitleBreakPoints>
                        </PartialBlueUnderlinedButton>
                        <Grid container direction="column" display="flex">
                            <Grid item sx={{ paddingBottom: 3, paddingLeft: 1}}>
                                <Typography variant="p">
                                    For security please close your browser window and we hope to see you again soon!
                                </Typography>
                            </Grid>

                            <Grid item sx={{ paddingBottom: 2}}>
                                <Button onClick={handleCloseButton}>Close</Button>
                            </Grid>
                        </Grid>

                    </Grid>



                </Grid>
                </Box>
            </Box>
        </>
    )
};

export default Goodbye;
