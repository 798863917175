import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { keyframes } from "@emotion/react";

const enterKeyframe = keyframes`
  0% {
    transform: scale(0);
    opacity: .8;
  }
  100% {
    transform: scale(1);
    opacity: .8;
  }
`;
const MenuButton = styled(Button)`
  text-decoration-color: #b8ddf3;
  text-decoration-thickness: 4px;
  letter-spacing: 4px;
  padding: 5px;
  line-height: .5;
  margin-left: 10px;
  &:hover {
    background-color: #b8ddf3;
    line-height: .5;
  }
  && .MuiTouchRipple-child {
    background-image: linear-gradient(to right, #b8ddf3, #ffffff);
  }
  && .MuiTouchRipple-rippleVisible {
    opacity: 1;
    animation-name: ${enterKeyframe};
    animation-duration: 550ms;
    animation-timing-function: ${({ theme }) =>
    theme.transitions.easing.easeInOut};
  }
`;
export default MenuButton;
