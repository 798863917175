import {FormControl, Grid, TextField, Typography} from "@mui/material";
import PartialBlueUnderlinedButton from "../button/partialBlueUnderlinedTitle";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {custom} from "../custom";
import FadeButton from "../button/fadeButton";
import AccountInfo from "../parse/accountInfo";
import * as React from "react";
import {useEffect, useState} from "react";
import Confirm from "../modals/confirm";
import {Link} from "react-router-dom";
import EditIcon from '../icon/editIcon';
import cleanText from "../helper/clean";
import AccountUpdate from "../parse/accountUpdate";
import Alert from "../modals/alert";

const styles = {
    paperContainer: {
        width: "100%",
        backgroundImage: `url(${"../backgrounds/about-bg.png"})`,
        backgroundAttachment: 'scroll',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: 3,
    }
};

const TitleBreakPoints = styled('span')(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        fontSize: 34,
        paddingBottom: 0,
        color: custom.color.about,
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 36,
        paddingBottom: 0,
        color: custom.color.about,
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: 34,
        paddingBottom: 0,
        color: custom.color.about,
    },
}));

function AccountForm() {

    const [confirmDelete, setConfirmDelete] = useState(false);

    // Load data
    const [showRetAccount,setShowRetAccount] = useState('block');
    const [showEditAccount,setShowEditAccount] = useState('none');

    // Show toggle
    const [editToggle,setEditToggle] = useState(false);

    // Form data
    const [firstNameVal,setFirstNameVal] = useState('');
    const [lastNameVal,setLastNameVal] = useState('');
    const [emailVal,setEmailVal] = useState('');
    const [phoneVal,setPhoneVal] = useState('');
    const [address1Val,setAddress1Val] = useState('');
    const [address2Val,setAddress2Val] = useState('');
    const [addressCityVal,setAddressCityVal] = useState('');
    const [addressStateVal,setAddressStateVal] = useState('');
    const [addressZipVal,setAddressZipVal] = useState('');

    // Button
    const [buttonText,setButtonText] = useState('save');
    const [isDisabled,setIsDisabled] = useState(false);

    // Alert
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    // Valid form
    const [isValidForm,setIsValidForm] = useState(false);

    // Popover for Square Portal
    // const [anchorEl, setAnchorEl] = useState(null);
    // const handlePopoverOpen = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handlePopoverClose = () => {
    //     setAnchorEl(null);
    // };
    // const open = Boolean(anchorEl);

    // Popover for Appointment
    // const [anchorElAppt, setAnchorElAppt] = useState(null);
    // const handleApptPopoverOpen = (event) => {
    //     setAnchorElAppt(event.currentTarget);
    // };
    // const handleApptPopoverClose = () => {
    //     setAnchorElAppt(null);
    // };
    // const openAppt = Boolean(anchorElAppt);

    // Data var declaration
    let retCustomerData;

    // Call account data
    const CallData = () => {
        return AccountInfo();
    }

    // Set data
    retCustomerData = CallData();

    function EmailSection(){
        if(retCustomerData && retCustomerData.emailAddress === ''){
            return null
        } else if(retCustomerData && retCustomerData.emailAddress){
            return <>
                <Grid item sx={{ pb: 2}}>
                    <Typography variant="h1" sx={{ fontWeight: "bold" }}>Email:</Typography>
                    <Typography variant="h1">{retCustomerData ? retCustomerData.emailAddress : ''}</Typography>
                </Grid>
            </>
        }
        return null
    }

    function AddressSection() {
        if (retCustomerData
            && retCustomerData.address
            && retCustomerData.address.addressLine1 === ''
            && retCustomerData.address.addressLine2 === ''
            && retCustomerData.address.locality === ''
            && retCustomerData.address.administrativeDistrictLevel1 === ''
            && retCustomerData.address.postalCode === '') {
            return null
        } else if (retCustomerData && retCustomerData.address) {
            return <>
                <Grid item sx={{pb: 2}}>
                    <Typography variant="h1" sx={{fontWeight: "bold"}}>Address:</Typography>
                    <Typography
                        variant="h1">{retCustomerData.address ? retCustomerData.address.addressLine1 : ''} {retCustomerData.address && retCustomerData.address.addressLine2 ? ', ' + retCustomerData.address.addressLine2 : ''}</Typography>
                    <Typography
                        variant="h1">{retCustomerData.address ? retCustomerData.address.locality : ''}, {retCustomerData.address ? retCustomerData.address.administrativeDistrictLevel1 : ''} {retCustomerData.address ? retCustomerData.address.postalCode : ''}</Typography>
                </Grid>
            </>
        }
        return null
    }

    function deleteClickHandler() {
        // Turn this into JSX and return a value !!!!!!!
        setConfirmDelete(true);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setButtonText("saving..");
        setIsDisabled(true);

        //console.log("Saving data...");

        //console.log("ELEMENT NAME: " + JSON.stringify(cleanText(document.getElementById("firstNameFieldId").value,'text')))
        //console.log("ELEMENT PHONE: " + JSON.stringify(cleanText(document.getElementById("cellPhoneFieldId").value,'phone')))

        const phoneCheckVal = cleanText(document.getElementById("cellPhoneFieldId").value,'phone')


        if((phoneCheckVal.substring(0,1) === '1' && phoneCheckVal.length === 11) || (phoneCheckVal.substring(0,1) !== '1' && phoneCheckVal.length === 10
            && cleanText(document.getElementById("firstNameFieldId").value, 'text').length <= 30)){

                retCustomerData = {};
                // Trigger to execute the save in the JSX below (isValidForm is the trigger)
                setIsValidForm(true)
                setIsDisabled(false)
                setButtonText("saving...")

                setFirstNameVal(cleanText(document.getElementById("firstNameFieldId").value,'text'));
                setLastNameVal(cleanText(document.getElementById("lastNameFieldId").value,'text'));
                setEmailVal(cleanText(document.getElementById("emailAddressFieldId").value,'email'));
                setPhoneVal(cleanText(document.getElementById("cellPhoneFieldId").value,'phone'));
                setAddress1Val(cleanText(document.getElementById("addressLine1FieldId").value,'text'));
                setAddress2Val(cleanText(document.getElementById("addressLine2FieldId").value,'text'));
                setAddressCityVal(cleanText(document.getElementById("cityFieldId").value,'text'));
                setAddressStateVal(cleanText(document.getElementById("stateFieldId").value,'text'));
                setAddressZipVal(cleanText(document.getElementById("postalCodeFieldId").value,'text'));



        } else {
            setIsValidForm(false)
            setIsDisabled(false)
            setButtonText("save")
            setShowAlert(true);
            setAlertMessage('When updating your info, be sure to use a US cell phone number with all 10 numbers, including area code. And the first name should be no more than 30 characters long. All other fields are optional.');
        }

        console.log("Form: " + isValidForm + ' ' + showAlert)
        console.log("phoneVal: " + phoneVal)
        console.log("firstNameVal: " + firstNameVal)

    };

    useEffect( () => {
        if(editToggle === false){
            setShowRetAccount('block');
            setShowEditAccount('none');
        }else if(editToggle === true){
            setShowRetAccount('none');
            setShowEditAccount('block');
        }
    },[editToggle]);

    // function saveClickHandler(){
    //     console.log("SAVE EDIT")
    // }

    function RetAccount(){

        return <>
            <Grid item xxs={12} xs={12} sm={12} md={6} lg={6} xl={6}
                  sx={{ marginLeft: 5, paddingBottom: 2  }}
                  display={showRetAccount}
            >
                <Grid container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{ pb: 2 }}
                >
                    <Grid item sx={{ pb: 2}}>
                        <Typography variant="h1" sx={{ fontWeight: "bold" }}>Name:</Typography>
                        <Typography variant="h1">{retCustomerData ? retCustomerData.givenName : ''} {retCustomerData ? retCustomerData.familyName : ''}</Typography>
                    </Grid>
                    <EmailSection/>
                    <AddressSection/>
                    <Grid item sx={{ pb: 2}}>
                        <Typography variant="h1" sx={{ fontWeight: "bold" }}>Cell Phone:</Typography>
                        <Typography variant="h1">{retCustomerData ? retCustomerData.phoneNumber : ''}</Typography>
                    </Grid>
                    {/*<Grid item sx={{ pb: 2, width: '80%'}}>*/}
                    {/*    <Typography variant="p">*/}
                    {/*        *** Notifications will come to your Cell Phone and/or Email Address on file.*/}
                    {/*        Click the edit icon above to add or modify.*/}
                    {/*        Cell Phone and First Name are required to use this website.*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
        </>

    }

    function EditAccount(){

        return <>
            <Alert message={alertMessage} showAlert={showAlert} setShowAlert={setShowAlert}/>
            <Grid item xxs={12} xs={12} sm={12} md={6} lg={6} xl={6}
                  sx={{ marginLeft: 5, paddingBottom: 2  }}
                  display={showEditAccount}
            >
                <Grid container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{ pb: 2 }}
                >
                    <FormControl>
                        <form onSubmit={handleSubmit}>
                            <Grid item sx={{ pb: 2}}>
                                {/*<Typography variant="h1">{retCustomerData ? retCustomerData.givenName : ''} {retCustomerData ? retCustomerData.familyName : ''}</Typography>*/}
                                <TextField type="text" id="firstNameFieldId" required={true} label="First Name" variant="outlined" defaultValue={firstNameVal !== '' ? firstNameVal : retCustomerData.givenName}/>
                                <TextField id="lastNameFieldId" label="Last Name" variant="outlined" defaultValue={ lastNameVal !== '' ? lastNameVal : retCustomerData.familyName}/>
                            </Grid>
                            <Grid item sx={{ pb: 2}}>
                                {/*<Typography variant="h1" sx={{ fontWeight: "bold" }}>Phone:</Typography>*/}
                                <TextField id="cellPhoneFieldId" label="Cell Phone" required={true} inputProps={{ maxLength: 11 }} variant="outlined" defaultValue={ phoneVal !== '' ? phoneVal : cleanText(retCustomerData.phoneNumber,'phone')}/>
                            </Grid>
                            <Grid item sx={{ pb: 2}}>
                                {/*<Typography variant="h1" sx={{ fontWeight: "bold" }}>Email:</Typography>*/}
                                <TextField id="emailAddressFieldId" label="Email" variant="outlined" defaultValue={ emailVal !== '' ? emailVal : retCustomerData.emailAddress}/>
                            </Grid>
                            <Grid item sx={{ pb: 2}}>
                                {/*<Typography variant="h1" sx={{ fontWeight: "bold" }}>Address:</Typography>*/}
                                <TextField id="addressLine1FieldId" label="Address Line 1" variant="outlined" defaultValue={ address1Val !== '' ? address1Val : retCustomerData.address ? retCustomerData.address.addressLine1 : '' }/>
                            </Grid>
                            <Grid item sx={{ pb: 2}}>
                                <TextField id="addressLine2FieldId" label="Address Line 2" variant="outlined" defaultValue={ address2Val !== '' ? address2Val : retCustomerData.address ? retCustomerData.address.addressLine2 : ''}/>
                            </Grid>
                            <Grid item sx={{ pb: 2}}>
                                <TextField id="cityFieldId" label="City" variant="outlined" defaultValue={ addressCityVal !== '' ? addressCityVal : retCustomerData.address ? retCustomerData.address.locality : ''}/>
                                <TextField id="stateFieldId" label="State" variant="outlined" defaultValue={ addressStateVal !== '' ? addressStateVal : retCustomerData.address ? retCustomerData.address.administrativeDistrictLevel1 : '' }/>
                            </Grid>
                            {/*<Typography variant="h1">{retCustomerData.address ? retCustomerData.address.administrativeDistrictLevel1 : ''}</Typography>*/}
                            <Grid item sx={{ pb: 2}}>
                                <TextField id="postalCodeFieldId" label="Zip Code" variant="outlined" defaultValue={addressZipVal !== '' ? addressZipVal : retCustomerData.address ? retCustomerData.address.postalCode : ''}/>
                            </Grid>
                            <Grid item>
                                <FadeButton
                                    type="submit"
                                    disabled={isDisabled}
                                    sx={{ width: "100px"
                                        , height: "40px"
                                        , marginBottom: {xxs: 2, xs: 2, sm: 2, md: 3 }
                                        , marginLeft: {xxs: 4, xs: 4, sm: 4, md: 3 }
                                        , marginTop: {xxs: 2, xs: 2, sm: 2, md: 3 }
                                        , fontSize: 20
                                        , textTransform: 'lowercase'
                                        , backgroundColor: '#b8ddf3'
                                    }}>{buttonText}
                                </FadeButton>

                                {/* TODO: Refactor above button and onClick event to "confirm sign out," not "delete." */}
                            </Grid>
                        </form>
                    </FormControl>
                    <AccountUpdate
                        validData={isValidForm}
                        email={emailVal}
                        phone={phoneVal}
                        firstName={firstNameVal}
                        lastName={lastNameVal}
                        addressLine1={address1Val}
                        addressLine2={address2Val}
                        addressCity={addressCityVal}
                        addressState={addressStateVal}
                        addressZip={addressZipVal}
                        setFirstNameVal={setFirstNameVal}
                    />
                </Grid>
            </Grid>
        </>

    }

    return (
        <Box style={styles.paperContainer} height="100%" sx={{ paddingTop: '9%', paddingBottom: {xs: '100px', sm: '100px'} }} // paddingBottom is for page text spacing
             display="block">
            <Box
                sx={{
                    display: 'flex'
                    , justifyContent: 'center'
                    , backgroundColor: '#fff'
                    , marginLeft: '5%'
                    , marginRight: '5%'
                    , marginBottom: '5%'
                }}
                display="block"
            >

                <Grid container direction="column" sx={{ width: "100%" }}>
                    <Grid item>
                        <Grid container direction="row">

                            <Grid item lg={3}
                                  sx={{ marginLeft: 4  }}
                            >
                                <PartialBlueUnderlinedButton
                                    alt="Account"
                                    disabled
                                    sx={{
                                        letterSpacing: 12,
                                        paddingLeft: 0,
                                        paddingBottom: 0,
                                    }}
                                >
                                    <TitleBreakPoints>
                                        account
                                    </TitleBreakPoints>
                                </PartialBlueUnderlinedButton>
                                <EditIcon size={35} editFunction={ () => setEditToggle(!editToggle)} color="#b8ddf3" />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" sx={{ width: "100%" }}>

                            {/* Each one is rendered conditionally by state vars */}
                            <RetAccount/>
                            <EditAccount/>

                            <Grid item lg={3}
                                  sx={{ marginLeft: {xxs: 4, xs: 4, sm: 4, md: 3 }, paddingBottom: 2  }}
                            >
                                <Grid container
                                      direction="column"
                                      justifyContent="flex-start"
                                      alignItems="flex-start"
                                      sx={{ pb: 2 }}
                                >
                                    <Grid item>
                                        <Typography variant="h1" sx={{ fontWeight: "bold" }}>Upcoming Appointments & Profile:</Typography>
                                    </Grid>
                                    <Grid item sx={{ pb: 0 }}>
                                        <Typography variant="p">
                                            *** Check your stylist's team profile page or confirmation email or text for appointment information.
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ pb: 0 }}>
                                        <FadeButton
                                            component={Link}
                                            to={'/team'}
                                            sx={{ width: "240px"
                                                , height: "40px"
                                                , marginBottom: {xxs: 2, xs: 2, sm: 2, md: 3 }
                                                , marginTop: {xxs: 2, xs: 2, sm: 2, md: 3 }
                                                , fontSize: 20
                                                , textTransform: 'lowercase'
                                                , backgroundColor: '#b8ddf3'
                                            }}>stylist team profiles
                                        </FadeButton>
                                    </Grid>
                                    <Grid item>
                                        {/*<FadeButton*/}
                                        {/*    onClick={ () => window.open('https://squareup.com/appointments/book/profile/ia3u8n467bvxvt/190NRE72W8J5T/reservations','_blank')}*/}
                                        {/*    sx={{ width: "240px"*/}
                                        {/*        , height: "40px"*/}
                                        {/*        , marginBottom: {xxs: 2, xs: 2, sm: 2, md: 3 }*/}
                                        {/*        , marginTop: {xxs: 2, xs: 2, sm: 2, md: 3 }*/}
                                        {/*        , fontSize: 20*/}
                                        {/*        , textTransform: 'lowercase'*/}
                                        {/*        , backgroundColor: '#b8ddf3'*/}
                                        {/*    }}>View/Modify Profile*/}
                                        {/*</FadeButton>*/}
                                        {/*<Popover*/}
                                        {/*    id="appointments-popover"*/}
                                        {/*    sx={{*/}
                                        {/*        pointerEvents: 'none',*/}
                                        {/*    }}*/}
                                        {/*    open={openAppt}*/}
                                        {/*    anchorEl={anchorElAppt}*/}
                                        {/*    anchorOrigin={{*/}
                                        {/*        vertical: 'bottom',*/}
                                        {/*        horizontal: 'left',*/}
                                        {/*    }}*/}
                                        {/*    transformOrigin={{*/}
                                        {/*        vertical: 'top',*/}
                                        {/*        horizontal: 'left',*/}
                                        {/*    }}*/}
                                        {/*    onClose={handleApptPopoverClose}*/}
                                        {/*    disableRestoreFocus*/}
                                        {/*>*/}
                                        {/*    <Typography sx={{ p: 1 }}>You can view or modify your appointments by using this button to log in to Square, but*/}
                                        {/*        you can also view or modify your appointments from your confirmation email or text message.*/}
                                        {/*    </Typography>*/}
                                        {/*</Popover>*/}
                                    </Grid>
                                    {/*<Grid item sx={{ paddingTop: 5, paddingBottom: 5, width: '95%' }}>*/}
                                    {/*    <Typography*/}
                                    {/*        aria-owns={open ? 'square-portal-popover' : undefined}*/}
                                    {/*        aria-haspopup="true"*/}
                                    {/*        onMouseEnter={handlePopoverOpen}*/}
                                    {/*        onMouseLeave={handlePopoverClose}*/}
                                    {/*        variant="p"*/}
                                    {/*    >*/}
                                    {/*        <a href='https://profile.squareup.com/'*/}
                                    {/*           target='_blank'*/}
                                    {/*           rel="noreferrer"*/}
                                    {/*        >Square Profile for All Square Sellers</a>*/}
                                    {/*    </Typography>*/}

                                    {/*    <Popover*/}
                                    {/*        id="square-portal-popover"*/}
                                    {/*        sx={{*/}
                                    {/*            pointerEvents: 'none',*/}
                                    {/*        }}*/}
                                    {/*        open={open}*/}
                                    {/*        anchorEl={anchorEl}*/}
                                    {/*        anchorOrigin={{*/}
                                    {/*            vertical: 'bottom',*/}
                                    {/*            horizontal: 'left',*/}
                                    {/*        }}*/}
                                    {/*        transformOrigin={{*/}
                                    {/*            vertical: 'top',*/}
                                    {/*            horizontal: 'left',*/}
                                    {/*        }}*/}
                                    {/*        onClose={handlePopoverClose}*/}
                                    {/*        disableRestoreFocus*/}
                                    {/*    >*/}
                                    {/*        <Typography sx={{ p: 1 }}>This link is to modify your info for ALL Square sellers.*/}
                                    {/*            Use the EDIT icon at the top of the page to modify information for Afton Blu.*/}
                                    {/*        </Typography>*/}
                                    {/*    </Popover>*/}

                                    {/*</Grid>*/}
                                    <Grid item lg={3}>
                                        <FadeButton
                                            onClick={deleteClickHandler}
                                            sx={{ width: "100px"
                                                , height: "40px"
                                                , marginBottom: {xxs: 2, xs: 2, sm: 2, md: 3 }
                                                , marginTop: {xxs: 2, xs: 2, sm: 2, md: 3 }
                                                , fontSize: 20
                                                , textTransform: 'lowercase'
                                                , backgroundColor: '#b8ddf3'
                                            }}>Sign Out
                                        </FadeButton>
                                        <Confirm showConfirmDelete={confirmDelete} />
                                        {/* TODO: Refactor above button and onClick event to "confirm sign out," not "delete." */}
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Box>
        </Box>

    )
};

export default AccountForm;
