// import SignIn from "../components/auth/signin";
// import BookingModule from "../components/sections/bookingModule";
import {LoginButton} from "../components/auth/loginButton";

export default function BookNow(props) {

    function getBookingState(){
        if(!props.validatedFormat){
            return (
                <>
                    <LoginButton />

                </>
            )
        }else if(props.validatedFormat){

            return (
                <>
                    {/*<BookingModule />*/}

                </>
            )
        }else{
            return null
        }
    }

    return getBookingState();

};
