import Api from "../callApi/getApi";
import {FormControl, InputLabel, ListSubheader, MenuItem, Select, Typography} from "@mui/material";

export default function ServiceDateTimes (props) {
    const { serviceId, stylistId, handleDateChange, isDisabled, disabledColor, sliderWeeks } = props

    function getDateString(dateVar){
        return new Date(dateVar).toDateString()
    }

    function getTimeString(dateVar){
        return new Date(dateVar).toLocaleTimeString('en-US')
    }

    function getDateTimeString(dateTime) {
        const val1 = getDateString(dateTime)
        const val2 = getTimeString(dateTime)
        return val1 + ' @ ' + val2
    }

    if(serviceId && serviceId !== '') {
        const { ret, isLoading, isError } = Api("/api/appointments/?stylistId=" + stylistId + "&serviceId=" + serviceId + "&slider=" + sliderWeeks)

        if (isLoading) return <Typography variant="h2" sx={{paddingLeft: 2}}>loading...</Typography> // <Spinner />
        if (isError) return <Typography variant="h2" sx={{paddingLeft: 2}}>data load error...</Typography> // <Error />

        return <>
            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel id="aftonBluDateTimeLabel">Select Date/Time</InputLabel>
                <Select
                    labelId="aftonBluDateTimeLabel"
                    id="aftonBluDateTime"
                    label="Select Date/Time"
                    onChange={handleDateChange}
                    sx={{ backgroundColor: disabledColor }}
                    autoWidth
                    defaultValue=""
                >

                    <ListSubheader>Week {sliderWeeks}</ListSubheader>
                    {ret.map((service) => (
                        <MenuItem
                            key={service.startAt}
                            value={service}>{getDateTimeString(service.startAt)}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    }

    return null

}

