const teamMemberIdTinaChristoff = process.env.REACT_APP_SQUARE_TEAM_MEMBER_ID_FOR_TINA_CHRISTOFF

export const stylistObject = {"stylists":[
        { firstLastName:"Tina Christoff"
            , stylistFilter: ['all','hair','management']
            , profileOptions:[
                {
                    minHeight: '280px'
                    , stylistImgMain: '/img/TinaChristoff.jpg'
                    , stylistImg: '/img/TinaChristoff-square.jpg'
                    , stylistWidth: '100px'
                    , stylesImg: ''
                    , stylesWidth: ''
                    , modalDimensions: {height: '100%', maxWidth: '100%', pt: 2}
                    , stylistDescription: "During her 26 year career in central Ohio, Tina has been educated by some of the industries iconic talents. As owner of Afton Blu, Tina decided to take what she learned and loved, leave behind what didn't work and create a space that both clients and independent hair stylists would love.  Afton Blu is an open concept booth rental salon focused on community and family.  The community of guests that we serve and the family of stylists that work together to provide a relaxing atmosphere.  When Tina is not in the salon she can be found enjoying time with her husband Chris. Making memories with her children Nicholas, Gabby, and Olivia. Or walking and spoiling her dog Wally. Please click below to schedule an appointment."
                    , bookingComponent: 'module'
                    , bookingModuleStylistId: teamMemberIdTinaChristoff
                    , bookingCompLink: ''
                    , myAppointmentsLink: 'https://squareup.com/appointments/book/profile/ia3u8n467bvxvt/190NRE72W8J5T/reservations'
                    , socialMediaLinks: [
                        {
                            yelp: ''
                            , twitter: ''
                            , instagram: 'https://www.instagram.com/afton_blu/?hl=en'
                            , facebook: ''
                        }
                    ]
                }
            ]},
        { firstLastName:"April Fravel"
            , stylistFilter: ['all','nails']
            , profileOptions:[
                {
                    minHeight: '280px'
                    , stylistImgMain: '/img/AprilFravel.jpeg'
                    , stylistImg: '/img/AprilFravel-square.jpeg'
                    , stylistWidth: '100px'
                    , stylesImg: ''
                    , stylesWidth: ''
                    , modalDimensions: {height: '100%', maxWidth: '100%', pt: 2}
                    , stylistDescription: "April has been a licensed manicurist since 1996 and a licensed esthetician since 2006. After working in one of the biggest salons in the Columbus area she wanted to venture out on her own and have a space that reflects her personality. She specializes in natural looking acrylic nails, gel manicures, pedicures and facial waxings. You can guarantee that you’re in good hands with April. She is not only dedicated to her work, she wants you to feel relaxed and comfortable while having a service done. When April isn’t working, she is spending time with her family and pets, camping and being outdoors. Reach me anytime at: 614-205-9298."
                    , bookingComponent: 'text'
                    , bookingModuleStylistId: ''
                    , bookingCompLink: 'Text or call: 614-205-9298'
                    , myAppointmentsLink: '/aprilfravel'
                    , socialMediaLinks: [
                        {
                            yelp: ''
                            , twitter: ''
                            , instagram: 'https://www.instagram.com/abunlimited22/?hl=en'
                            , facebook: ''
                        }
                    ]
                }
            ]},
        { firstLastName:"Danielle Cummings "
            , stylistFilter: ['all','hair']
            , profileOptions:[
                {
                    minHeight: '280px'
                    , stylistImgMain: '/img/DaniCummings.jpg'
                    , stylistImg: '/img/DaniCummings-square.jpg'
                    , stylistWidth: '100px'
                    , stylesImg: ''
                    , stylesWidth: ''
                    , modalDimensions: {height: '100%', maxWidth: '100%', pt: 2}
                    , stylistDescription: "Danielle (Dani) has been in the industry for 5 years. Hair is not only a passion for her but a way to connect with others. She specializes in blonde and color services. As a certified Davines Educator, she is always promoting hair and scalp health above anything else. Working exclusively with davines color and product, Dani will always create beautiful hair for her clients. \n" +
                        "\n" +
                        "outside the chair - she loves traveling and teaching others about Davines. going on adventures with her dog baker (the cutest golden retriever). spending time with friends and family. being outside - hiking, exploring, drinks on a patio, and food!"
                    , bookingComponent: 'text'
                    , bookingModuleStylistId: ''
                    , bookingCompLink: 'daniduhhshair@gmail.com'
                    , myAppointmentsLink: 'https://www.vagaro.com/daniduhhshair'
                    , socialMediaLinks: [
                        {
                            yelp: ''
                            , twitter: ''
                            , instagram: 'https://www.instagram.com/daniduhhshair/?hl=en'
                            , facebook: ''
                        }
                    ]
                }
            ]},
        { firstLastName:"Amy Thiaw"
            , stylistFilter: ['all','hair']
            , profileOptions:[
                {
                    minHeight: '280px'
                    , stylistImgMain: '/img/AmyThiaw-dal-e.png'
                    , stylistImg: '/img/AmyThiaw-square.jpg'
                    , stylistWidth: '100px'
                    , stylesImg: ''
                    , stylesWidth: ''
                    , modalDimensions: {height: '100%', maxWidth: '100%', pt: 2}
                    , stylistDescription: "Amy Thiaw has dreamed of being behind the chair since grade school. She has " +
                        "been in the industry since 1998. Amy had previously worked at Mario Tricoci as an educator and " +
                        "stylist who specialized in cutting for eleven years. When Amy isn't at the salon making people " +
                        "feel better about themselves you will find her at her son, Marcus's sporting events an enjoying " +
                        "time with family and friends."
                    , bookingComponent: 'text'
                    , bookingModuleStylistId: ''
                    , bookingCompLink: 'amy23aftonblu@gmail.com'
                    , myAppointmentsLink: 'https://amy-thiaw.square.site/'
                    , socialMediaLinks: [
                        {
                            yelp: ''
                            , twitter: ''
                            , instagram: 'https://www.instagram.com/'
                            , facebook: ''
                        }
                    ]
                }
            ]},
        { firstLastName:"Beth Blubaugh"
            , stylistFilter: ['all','hair']
            , profileOptions:[
                {
                    minHeight: '280px'
                    , stylistImgMain: '/img/BethBlubaugh2.jpg'
                    , stylistImg: '/img/BethBlubaugh-square.jpg'
                    , stylistWidth: '100px'
                    , stylesImg: ''
                    , stylesWidth: ''
                    , modalDimensions: {height: '100%', maxWidth: '100%', pt: 2}
                    , stylistDescription: "Beth has been styling hair for over twenty years, a career that began in Columbus, OH. She has further her stylist’s education by taking courses in Chicago and throughout the US with some top leading stylist." +
                        "She has a specialized approach to color and truly makes it an art form." +
                        "When she is not coloring, she can be found at early morning workout classes, running around with her husband and boys, Nick, Drew and Will, and enjoying time with her family at the lake." +
                        "Please contact me at 740-751-1601 or click below to book an appointment. "
                    , bookingComponent: 'text'
                    , bookingModuleStylistId: ''
                    , bookingCompLink: 'Text or call: 740-751-1601'
                    , myAppointmentsLink: 'https://beth-blubaugh.square.site'
                    , socialMediaLinks: [
                        {
                            yelp: ''
                            , twitter: ''
                            , instagram: 'https://www.instagram.com/bethblu/?hl=en'
                            , facebook: ''
                        }
                    ]
                }
            ]},
        { firstLastName:"Kim Evans"
            , stylistFilter: ['all','hair']
            , profileOptions:[
                {
                    minHeight: '280px'
                    , stylistImgMain: '/img/KimEvans-dal-e.png'
                    , stylistImg: '/img/KimEvans-square.jpg'
                    , stylistWidth: '100px'
                    , stylesImg: ''
                    , stylesWidth: ''
                    , modalDimensions: {height: '100%', maxWidth: '100%', pt: 2}
                    , stylistDescription: "Kim Evans started her career 35 years ago in German Village as an apprentice for one of the top hairstylist in Columbus at that time. " +
                        "Over the years she has seen fashion and hair come full circle but continues to educate herself in the latest trends in color and haircuts. " +
                        "Her love of hair ,connecting with her clients and building long lasting relationships will be instantly recognized when you sit in her chair!" +
                        "When Kim is not in the salon she can be found on her farm with her family caring for her horses ,gardening or making memories with her two adorable grandsons."
                    , bookingComponent: 'text'
                    , bookingModuleStylistId: ''
                    , bookingCompLink: 'Text or call: 614-374-5943'
                    , myAppointmentsLink: 'https://kim-evansafton-blu-salon.square.site'
                    , socialMediaLinks: [
                        {
                            yelp: ''
                            , twitter: ''
                            , instagram: 'https://www.instagram.com/'
                            , facebook: ''
                        }
                    ]
                }
            ]},
        { firstLastName:"Olivia Christoff"
            , stylistFilter: ['all','hair']
            , profileOptions:[
                {
                    minHeight: '280px'
                    , stylistImgMain: '/img/OliviaChristoff-dal-e.png'
                    , stylistImg: '/img/OliviaChristoff-square.png'
                    , stylistWidth: '100px'
                    , stylesImg: ''
                    , stylesWidth: ''
                    , modalDimensions: {height: '100%', maxWidth: '100%', pt: 2}
                    , stylistDescription: "Olivia is new to the hair scene as a licensed stylist, but not to the hair world. " +
                        "She grew up around the industry as a third generation stylist in her family. " +
                        "She loves blonding and vivid colors as well as smoothing textured hair. " +
                        "If great hair,chatting about new recipes, your latest binge or pets is your vibe, " +
                        "give Olivia a call at (614) 477-4374. She would love to discuss your hair dreams with you!"
                    , bookingComponent: 'text'
                    , bookingModuleStylistId: ''
                    , bookingCompLink: 'Text or call: (614) 477-4374'
                    , myAppointmentsLink: 'https://afton-blu.square.site/'
                    , socialMediaLinks: [
                        {
                            yelp: ''
                            , twitter: ''
                            , instagram: 'https://instagram.com/beautybyoliviac_'
                            , facebook: ''
                        }
                    ]
                }
            ]},
    ]};
