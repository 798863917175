import Box from "@mui/material/Box";
import UnderlinedButton from "../components/button/underlinedButton";
import {Grid} from "@mui/material";
import ProfileModal from "../components/modals/profileModal";
import {useParams} from "react-router-dom";
import {custom} from "../components/custom"

const telephoneNumber = custom.phone.areaCode + "-" + custom.phone.number

const styles = {
    paperContainer: {
        height: "667px",
        width: "100%",
        backgroundImage: `url(${"../backgrounds/home-bg-lt.jpeg"})`,
        backgroundAttachment: 'scroll',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: 3,
    }
};

function Home() {

    const { bookNow } = useParams();
    let openNow

    if(bookNow && bookNow === "open"){
        openNow = true
    } else {
        openNow = false
    }
    //console.log("bookNow: " + bookNow)
    //console.log("openNow: " + openNow)


    return (
        <Box style={styles.paperContainer} height="100%">

            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                height="100%"
            >
                <Grid item>
                    {/*<ProfileModal buttonFor="homePage" bookNowOpen={openNow} bookWith="Tina Christoff"/>*/}
                    <ProfileModal buttonFor="homePage" bookNowOpen={openNow} bookWith="" />
                </Grid>
                <Grid item>
                    <UnderlinedButton href={`tel:${telephoneNumber}`} >call salon</UnderlinedButton>
                </Grid>
            </Grid>
        </Box>
    )
};

export default Home;
