import * as React from 'react';
import {FormControl, Grid, TextField, Typography} from "@mui/material";
import * as global from "./custom";
import Container from "@mui/material/Container";
import BlueUnderlinedButton from "./button/blueUnderlinedButton";
import YpIcon from "./icon/yelpIcon";
import TwtrIcon from "./icon/twitterIcon";
import InstaIcon from "./icon/instagramIcon";
import FbIcon from "./icon/facebookIcon";
import MenuButton from "./button/menuButton";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import cleanText from "./helper/clean";
import Alert from "./modals/alert";
import AccountAdd from "./parse/accountAdd";
import Check from "./auth/check";
import EekotechButton from "./button/eekotechButton";

function Footer() {

    const footerIconSize = global.custom.icon.footer;
    const footerText = global.custom.text.footer;
    const footerColor = global.custom.color.footer;
    const footerButton = global.custom.button.footer;
    const pages = global.custom.pages;

    const [inputFirstName, setInputFirstName] = useState('');
    const [inputCellPhone, setInputCellPhone] = useState('');

    const [cellCleanVal, setCellCleanVal] = useState('');
    const [fullNameCleanVal, setFullNameCleanVal] = useState('');

    const [isValidForm, setIsValidForm] = useState(false);

    const [buttonText, setButtonText] = useState('join now');
    const [isDisabled, setIsDisabled] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        if(Check()){
            setIsDisabled(true)
        }else{
            setIsDisabled(false)
        }
    },[])

    const handleNameInput = (event) => {
        setInputFirstName(event.target.value);
    };

    const handlePhoneInput = (event) => {
        setInputCellPhone(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setButtonText("joining...")
        setIsDisabled(true)

        //console.log("First Name: " + inputFirstName)
        //console.log("Cell Phone: " + inputCellPhone)

        setCellCleanVal(cleanText(inputCellPhone,'phone'));
        setFullNameCleanVal(cleanText(inputFirstName, 'text'));

        if(cleanText(inputCellPhone,'phone').length === 10 && cleanText(inputFirstName, 'text').length <= 30){
            setIsValidForm(true);
            setIsDisabled(false)
            setButtonText("join now")
        } else {
            setIsValidForm(false);
            setIsDisabled(false)
            setButtonText("join now")
            setShowAlert(true);
            setAlertMessage('Be sure to use a US cell phone number with all 10 numbers, including area code. And the first name should be no more than 30 characters long.');
        }

        //console.log("Form: " + isValidForm + ' ' + showAlert)

    };

    return (
        <Container maxWidth="100%">
            <Alert message={alertMessage} showAlert={showAlert} setShowAlert={setShowAlert}/>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
            >
                <Grid item width="40%">
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="stretch"
                    >
                        <Grid item>
                            <div style={{ paddingLeft: "10%", paddingTop: 40 }}>
                                <img alt="" width={140} src="../AftonBluLogo.png" />
                                <Typography style={{ paddingTop: 16, width: 180 }}
                                    variant="subtitle1">
                                    Address:
                                </Typography>
                                <Typography sx={{ fontSize: footerText, color: footerColor }} >
                                    {global.custom.address.number} {global.custom.address.street}<br />
                                    {global.custom.address.city}, {global.custom.address.state} {global.custom.address.zip}<br />
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item>
                            <div style={{ paddingLeft: "10%", paddingBottom: 30, paddingTop: 20 }}>
                                <Typography variant="subtitle1">
                                    Telephone:
                                </Typography>
                                <Typography sx={{ fontSize: footerText, color: footerColor }}>
                                    ({global.custom.phone.areaCode}) {global.custom.phone.number}
                                </Typography>
                            </div>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item sx={{ marginRight: "0%" }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="stretch"
                    >
                        <Grid item width="100%">

                                <FormControl>
                                    <form onSubmit={handleSubmit}>

                                        <Box style={{ paddingTop: 40, marginLeft: '5%', marginRight: '5%' }}
                                             display="flex"
                                             justifyContent="flex-end"
                                             alignItems="flex-end"
                                        >

                                            <TextField id="join-now-cell"
                                                       label={(isDisabled ? "sign out to join" : "cell phone")}
                                                       variant="outlined"
                                                       size="small"
                                                       onChange={handlePhoneInput}
                                                       value={inputCellPhone}
                                                       disabled={isDisabled}
                                                       required={true}
                                            />
                                            <TextField id="join-now-f-name"
                                                       label={(isDisabled ? "sign out to join" : "first name")}
                                                       variant="outlined"
                                                       size="small"
                                                       onChange={handleNameInput}
                                                       value={inputFirstName}
                                                       disabled={isDisabled}
                                                       required={true}
                                            />
                                        </Box>
                                        {/*onSubmit={handleSubmit}*/}
                                        <Typography
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-end"
                                        >

                                            <BlueUnderlinedButton style={{ marginLeft: '5%', marginRight: '5%' }}
                                                                  disabled={isDisabled}
                                                                  type="submit"
                                            >
                                                {buttonText}
                                            </BlueUnderlinedButton>

                                        </Typography>
                                        {/*<input type="hidden" value={JSON.stringify(inputFirstName)} id="selectedDateTime"/>*/}
                                        {/*<input type="hidden" value={JSON.stringify(inputCellPhone)} id="selectedService"/>*/}
                                    </form>
                                </FormControl>

                                {/*TODO: Hide the join now component (create it above) and hide it when an account is added (AccountAdd - below)*/}
                                <Typography display="flex"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            variant="p"
                                            sx={{ paddingLeft: 3 }}
                                >
                                    <AccountAdd firstName={fullNameCleanVal} cell={cellCleanVal} validData={isValidForm}/>
                                </Typography>

                                {/*Footer Icons*/}
                                <Grid container
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      sx={{
                                          display: { xxs: 'flex', md: 'flex' },
                                          paddingTop: 4
                                      }}
                                >
                                    <Grid item>
                                        <YpIcon size={footerIconSize} width="20" />
                                    </Grid>
                                    <Grid item>
                                        <TwtrIcon size={footerIconSize} width="20" />
                                    </Grid>
                                    <Grid item>
                                        <InstaIcon size={footerIconSize} width="20" link={'https://www.instagram.com/afton_blu/?hl=en'} />
                                    </Grid>
                                    <Grid item>
                                        <FbIcon size={footerIconSize} width="20" link={'https://www.facebook.com/people/Afton_Blu/100073899301024/'} />
                                    </Grid>
                                </Grid>

                                {/*Footer Menu Buttons*/}
                                <Grid container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      sx={{
                                          display: { xxs: 'flex', md: 'flex' },
                                          width: '100%'
                                      }}
                                >
                                    <Grid item paddingLeft={1}>
                                        <Typography
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            {pages.map((page) => (

                                                <MenuButton
                                                    key={page}
                                                    sx={{ my: 4, display: { xxs: 'flex', md: 'flex', marginLeft: 1, marginRight: 1 }, fontSize: footerButton }}
                                                    component={Link}
                                                    to={'/' && page}
                                                >
                                                    {page}
                                                </MenuButton>

                                            ))}
                                        </Typography>

                                    </Grid>
                                </Grid>

                        </Grid>

                    </Grid>
                </Grid>


                <Grid container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ paddingBottom: 1 }}
                >
                    <Grid item>
                        <Typography sx={{ fontSize: footerText, color: footerColor }} >
                            © 2022 Copyright - AftonBlu. All right reserved.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontSize: footerText, color: footerColor }} >
                            <EekotechButton target={'_blank'} href={`https://eekotech.com/`}>Engineered by: EEKOTECH</EekotechButton>
                        </Typography>
                    </Grid>
                </Grid>


            </Grid>

        </Container>
    )
};

export default Footer;
