import CustomerInfo from "../parse/customerInfo"
import Redirect from "../helper/redirect";
import {useEffect} from "react";
import options from "../callApi/corsApi";

export default function AccountAdd(props) {
    // const customerId = props.customerId;
    const {cell,firstName,validData} = props;
    const query = "phoneNumber=" + cell + "&" + "firstName=" + firstName;
    //console.log("query1: " + query)

    function go(){
        const data = CustomerInfo({phone: cell, redirect: 'noAuthorizedRedirect'})
        //console.log("data: " + data)
        return data
    }

    const addOk = go();
    //console.log("data: " + addOk)

    useEffect(() => {
        if(addOk === '14cGnuB4c'){
            //console.log("Add ok...")
            const doCustomerAdd = async (query) => {
                const fetchCustomerAdd = new Request("/api/customerAdd?" + query)
                await fetch(fetchCustomerAdd,options)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }

                        return response.json();
                    })
                ;
            }
            doCustomerAdd(query).then(response => {
                //console.log("Added ok...")
                Redirect('/account')
            });

        }
    },[addOk,query])


    // console.log("DONE...")
    return null



}
