import {Typography} from "@mui/material";
import Api from "../callApi/getApi";
import LocalStorageSave from "../helper/localStorageSave"
import LocalStorageDelete from "../helper/localStorageDelete"
import {Link} from "react-router-dom";
import cleanText from "../helper/clean";
import Redirect from "../helper/redirect"

export default function CustomerInfo(props) {
    const emailAddress = props.email;
    const phoneNumber = props.phone;
    let query = '';
    if(emailAddress !== undefined && emailAddress.trim().length > 0){
        query = "emailAddress=" + emailAddress.trim()
    } else if(phoneNumber !== undefined && phoneNumber.trim().length > 0) {
        if(cleanText(phoneNumber.trim(),'phone').substring(0,1) === '1'){
            query = "phoneNumber=" + phoneNumber.trim()
        } else if (cleanText(phoneNumber.trim(),'phone').substring(0,1) !== '1'){
            query = "phoneNumber=+1" + phoneNumber.trim()
        }

    }

    const redirect = props.redirect ? props.redirect : "undefined";
    //console.log("query: " + query)
    const {ret, isLoading, isError} = Api("/api/customer?" + query)

    if (isLoading) return <Typography variant="h2" sx={{paddingLeft: 2}}>loading...</Typography> // <Spinner />
    if (isError) return <Typography variant="h2" sx={{paddingLeft: 2}}>data load error...</Typography> // <Error />

    if (ret && ret.customers && ret.customers.length > 0 && redirect === 'noAuthorizedRedirect'){ // user exists but user is entering from the JOIN NOW box so dont let them in yet
        //Only one customer was found
        // console.log(ret.customers[0])
        LocalStorageDelete();
        Redirect('/account')
        //console.log("1")
    } else if (ret && ret.customers && ret.customers.length > 0){  // user exists and entered from the login button ok
        //Only one customer was found
        // console.log(ret.customers[0])
        LocalStorageSave(ret, redirect);
        //console.log("2")
    } else if (redirect === 'noAuthorizedRedirect') {
        return "14cGnuB4c"  // ok to add
    } else {  // all other use cases
        LocalStorageDelete();
    }

    const NoUserExists = () => {
        return <>
            <Typography variant="h2" sx={{paddingLeft: 2}}>no user exists</Typography>
            <Typography variant="h2" sx={{paddingLeft: 2}}>
                <Link to="/account">try again</Link>
            </Typography>
        </>
    }

    return (ret.customers ? <Typography variant="h2" sx={{paddingLeft: 2}}>redirecting...</Typography> : <NoUserExists/>) // ret.customers[0].id || noUserExists.customers[0].id
}
