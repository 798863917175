// import {Grid, TextField, Typography} from "@mui/material";
// import PartialBlueUnderlinedTitle from "../button/partialBlueUnderlinedTitle";
// import BlueUnderlinedButton from "../button/blueUnderlinedButton";
// import {useEffect, useState} from "react";
import CustomerInfo from "../parse/customerInfo";
// import CleanText from "../helper/clean"
// import ValidateText from "../helper/validate"
// import {useAuth0} from "@auth0/auth0-react";
// import Check from "./check";

function ValidateCustomerCellPhone(props) {

    // Auth0 Authentication hooks
    // const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    // const [userMetadata, setUserMetadata] = useState(null);

    const redirect = props.redirect ? props.redirect : '/'
    const phone = props.phone
    //console.log("redirect: " + redirect)

    // const redirectPage = props.page //redirect to this page after signin is complete
    const inputs = { 'phone': phone } ;
    // const [returnData, setReturnData] = useState({customers: [{id: 0}] });

    const data = retCustomer(inputs)

    function retCustomer(inputs){
        return <CustomerInfo phone={inputs.phone} redirect={redirect} />
    }


        // useEffect(() => {
        //     if(returnData && redirect && redirect.length > 0 && Check()){
        //         window.location.replace(process.env.LOGIN_REDIRECT_BASE_URL + redirect)
        //     }
        // });



    return (
        <>
            <div>
                {/* try to return a map here instead, data is returning now*/}
                {(data.customers ? data.customers[0].id : data)}
            </div>
        </>
    )
};

export default ValidateCustomerCellPhone;
