export const custom = {
    icon: {
        main: 24,
        footer: 14,
    },
    pages: ['home', 'team', 'about', 'contact', 'shop'],
    stylistCategories: ['hair', 'nails', 'management', 'all'],
    mockProducts: ['shampoo', 'conditioner', 'hair spray', 'moose', 'hair paste'],
    text: {
        footer: 15,
    },
    color: {
        footer: '#000',
        stylists: '#fff',
        about: '#000',
        contact: '#000',
        productPlus: '#b8ddf3',
        drawerIcon: '#fff'
    },
    background: {
      contact: '#fff'
    },
    button: {
        footer: 10
    },
    address: {
        number: "5334",
        street: "Warner Rd",
        city: "Westerville",
        state: "OH",
        zip: "43081"
    },
    phone: {
        areaCode: "614",
        number: "245-4124"
    }
}
