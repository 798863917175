import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";

const styles1 = {
    fontWeight: 'bold'
    , fontFamily: 'Arial'
    , paddingBottom: '12px'
    , paddingTop: '12px'
};

const styles = {
    paperContainer: {
        height: "100%",
        width: "96%",
        backgroundImage: `url(${"../backgrounds/about-bg.png"})`,
        backgroundAttachment: 'scroll',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: 3,
    }
};

export default function AprilFravel() {
    return (
        <>
            <Box style={styles.paperContainer} height="100%" sx={{ p: '2%' }} // paddingBottom is for page text spacing
                 display="block"
                direction={'column'}
            >
                <Box
                    sx={{ backgroundColor: '#fff'
                        , p: 2
                    }}
                    display="block"
                >
                    <Grid container
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                        <Typography variant='stylistPageHeader' sx={{ pb: 2 }}>
                            April’s Beauty Unlimited @ Afton Blu Salon
                        </Typography>
                    </Grid>

                    <Grid container
                          direction="column"
                    >
                        <Grid item sx={{ pb: 2 }}>
                            <Typography variant='stylistPage1'>
                                PRICING FOR NAILS AND SKIN SERVICES
                            </Typography>
                        </Grid>
                        <Grid item>

                            <Typography variant='stylistPage2'>NAIL SERVICES</Typography>
                            <Typography variant='p'>
                                <ul>
                                    <li>
                                        Basic manicure (30 min)- Your nails will be shaped & buffed, cuticles treated, lotion applied to your hands and your choice of regular polish will be applied to your nails. $35
                                    </li><li>
                                        Deluxe spa manicure (40 min)- Take the basic manicure and add an exfoliating scrub to your hands and arms, then a hand and arm massage followed by heated warming mitts and your choice of regular polish. $42
                                    </li><li>
                                        Regular acrylic balance (1 hour) $52 & up
                                    </li><li>
                                        Whitening acrylic balance (1 hour 15 min) $70 & up
                                    </li><li>
                                        New set of acrylic nails (1 hour 15-30 min) $82 & up
                                    </li><li>
                                        Acrylic removal with a basic manicure (1 hour 15 min) $60
                                    </li><li>
                                        Gel manicure (40 min)- The Gel manicure is a durable & instantly dry manicure. Your nails will be shaped, buffed and cuticles treated. After the gel polish application, lotion is applied to your hands. $46
                                    </li><li>
                                        Gel manicure removal and reapplication (1 hour)- Your gel will be soaked and removed followed by a gel manicure or regular polish. $56
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant='stylistPage2'>ADD ONS</Typography>
                            <Typography variant='p'>
                                <ul>
                                    <li>
                                        Gel polish add on $12
                                    </li><li>
                                        Nail repair $10
                                    </li><li>
                                        French polish $8
                                    </li><li>
                                        Nail art $10 & up
                                    </li><li>
                                        Two or more colors of polish $5 & up
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='stylistPage2'>PEDICURE SERVICES</Typography>
                            <Typography variant='p'>
                                <ul>
                                    <li>
                                        The refresher pedicure (25 min)- For someone who is short on time or just needs a “refresher”. This speedy pedicure includes soaking, toenail shaping, cuticle work, lotion & regular polish applied. $45
                                    </li><li>
                                        Hydrotherapy spa pedicure (50 min)- Relax in a massage chair while your feet are soaking in a whirlpool tub. This pedicure includes nail and cuticle maintenance, use of a foot file, foot scrub & massage on the lower leg and foot, heated foot warmers and regular polish applied. $64
                                    </li><li>
                                        Gel hydrotherapy spa pedicure (1 hour)- You will get everything in the hydrotherapy pedicure in this service including gel polish on your toes. $76
                                    </li><li>
                                        Gel hydrotherapy pedicure with a removal and reapply (1 hour 15 minutes)- The name says exactly what you get! This pedicure is the gel pedicure, only with this one your old gel polish is removed and fresh gel or regular polish is applied. $82
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='stylistPage2'>WAX & TINT SERVICES</Typography>
                            <Typography variant='p'>
                                <ul>
                                    <li>
                                        Full face wax $68
                                    </li><li>
                                        Eyebrow wax $23
                                    </li><li>
                                        Lip wax $16
                                    </li><li>
                                        Chin wax $20
                                    </li><li>
                                        Sides of the face wax $25
                                    </li><li>
                                        Brow tint $25
                                    </li><li>
                                        Eyelash tint $35
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='stylistPage1'>CANCELLATION POLICY</Typography>
                            <Typography variant='stylistPage4'>
                                <br />
                                *If you cancel within 24 hours of your service you will be charged 50% of your service.
                                <br />
                                *If you do not show for your appointment you will be charged 100% of your service and will have to prepay for your next appointment.
                            </Typography>

                            <Box style={styles1}>
                                <Typography variant='stylistPage1'>LATE POLICY</Typography>
                                <Typography variant='stylistPage4'>
                                    <br />
                                    *I have a late policy that will be up to my discretion. If you are more than 10 minutes late you may be asked to reschedule your appointment and pay a cancellation fee.
                                </Typography>
                            </Box>

                            <Box>
                                <Typography variant='stylistPage3'>
                                    I love my career and it is very important to me to be prompt and on time. I respect your time I ask you for the same respect in return. If there is an occasion that I am running behind, I will reach out to you and let you know. I would appreciate it if you are running late to let me know as well. Thank you for your understanding and for your support!
                                    <br /><br />
                                </Typography>
                                <Typography variant='stylistPage4'>
                                    To make an appointment call or text April @ <a href={'tel:6142059298'}>614-205-9298</a>
                                    <br /><br />
                                    Appointments are by reservation only, hours may vary Monday-Friday
                                    <br /><br />
                                    Follow me on <a target={'_blank'} href={'https://www.instagram.com/abunlimited22/?hl=en'} rel="noreferrer">Instagram @ abunlimited22</a>
                                    <br /><br />
                                </Typography>
                            </Box>


                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </>
    )
};
