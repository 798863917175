import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { keyframes } from "@emotion/react";

const enterKeyframe = keyframes`
  0% {
    transform: scale(0);
    opacity: .8;
  }
  100% {
    transform: scale(1);
    opacity: .8;
  }
`;
const EekotechButton = styled(Button)`
  text-decoration-color: transparent;
  text-decoration-thickness: 4px;
  font-size: 14px;
  letter-spacing: 0px;
  padding: 2px;
  line-height: .5;
  && .MuiTouchRipple-child {
    background-image: linear-gradient(to right, #ffffff, #ffffff);
  }
  && .MuiTouchRipple-rippleVisible {
    opacity: 1;
    animation-name: ${enterKeyframe};
    animation-duration: 550ms;
    animation-timing-function: ${({ theme }) =>
    theme.transitions.easing.easeInOut};
  }
`;
export default EekotechButton;
