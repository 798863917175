import Redirect from "../helper/redirect"
import LocalStorageDelete from "../helper/localStorageDelete"

function SignOut(props) {
    const {doIt} = props

    if(doIt){
        LocalStorageDelete();
        Redirect("/goodbye");
    }

    return null

};

export default SignOut;
