import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";

export default function Alert(props) {
    let {message, showAlert, setShowAlert} = props
    const [open, setOpen] = useState(false);
    const styles = { color: '#000000', backgroundColor: '#ffffff'}

    useEffect(() => {
        if(showAlert === true){
            setOpen(true)
        }
    },[showAlert]);

    function handleCloseButton() {
        setOpen(false);
        setShowAlert(false);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseButton}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"
                    sx={styles}
                >
                        Alert
                </DialogTitle>
                <DialogContent
                    sx={styles}
                >

                    <Grid container direction="column" display="flex">
                        {/*<Typography variant="p" sx={{paddingBottom: 2}}>*/}
                        {/*    Message*/}
                        {/*</Typography>*/}
                        <Typography variant="p">
                            {message}
                        </Typography>
                    </Grid>

                </DialogContent>
                <DialogActions
                    sx={styles}
                >
                    <Button onClick={handleCloseButton}>Close</Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}
