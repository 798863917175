import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import ValidateCustomerCellPhone from "../components/auth/validateCustomerCellPhone";
import {Typography} from "@mui/material";

const Profile = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <Typography variant="h2" sx={{paddingLeft: 2}}>loading...</Typography>;
    }

    return (
        isAuthenticated && (
            // <ValidateCustomerEmail redirect="account" email={user.email} />
            <ValidateCustomerCellPhone phone={user.nickname} redirect="welcome"/> // Cell Phone comes in user.nickname from Auth0
            // <div>
            //     {JSON.stringify(user)}
            //     {/*<img src={user.picture} alt={user.name} />*/}
            //     {/*<h2>{user.name}</h2>*/}
            //     {/*<p>{user.email}</p>*/}
            // </div>
        )
    );
};

export default Profile;
