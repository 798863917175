import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import UnderlinedButton from "../button/underlinedButton";
import {DialogActions, DialogTitle, Grid, Pagination, Stack, Typography} from "@mui/material";
import BlueUnderlinedButton from "../button/blueUnderlinedButton";
import YpIcon from "../icon/yelpIcon";
import TwtrIcon from "../icon/twitterIcon";
import InstaIcon from "../icon/instagramIcon";
import FbIcon from "../icon/facebookIcon";
import BookingModule from '../sections/bookingModule';
import useWindowDimensions from '../helper/useWindowDimensions';
import {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {AiOutlineCalendar} from "react-icons/ai";
import FadeButton from "../button/fadeButton";
import * as stylistObject from "../objects/stylistObject";
import Check from "../../components/auth/check";
import {LoginButton} from "../auth/loginButton";
import getLocalStorage from "../localStorage/getLocalStorage"

const customerId = getLocalStorage("customerId")
const stylistProfiles = stylistObject.stylistObject;
const stylists = stylistObject.stylistObject.stylists;

export default function ProfileModal(props){
    const { buttonFor, bookWith, iconSize, bookNowOpen = false, imgSrc = '' } = props
    const [open, setOpen] = useState((bookNowOpen));
    const [ showServices, setShowServices ] = useState(false)

    const [arrayId, setArrayId] = useState(0);  // index of the first person to show
    const [newPage, setNewPage] = useState(1);

    const [firstLastName, setFirstLastName] = useState('');
    const [stylistImage,setStylistImage] = useState('');
    const [showPag,setShowPag] = useState('flex');

    useEffect(() => {
        if(bookWith.trim().length > 0){
            stylists.forEach((data,index) => {
                // console.log("index:" + index)
                if(data.firstLastName.includes(bookWith) ) {
                    setArrayId(index)
                    setFirstLastName(data.firstLastName)
                    setStylistImage(imgSrc)
                    setShowPag('none')
                }
            })
            // setArrayId(1)
        } else if (bookWith.trim().length === 0){
            // console.log("index:" + "0")
            setFirstLastName(stylistProfiles.stylists[arrayId].firstLastName)
        }
    },[bookWith,firstLastName,arrayId,imgSrc])

    const numStylists = stylistProfiles.stylists.length
    const minHeight = stylistProfiles.stylists[arrayId].profileOptions[0].minHeight
    const minStylistHeight = '100px'
    const stylistId = stylistProfiles.stylists[arrayId].profileOptions[0].bookingModuleStylistId
    const stylistImg = stylistProfiles.stylists[arrayId].profileOptions[0].stylistImg
    const stylistWidth = stylistProfiles.stylists[arrayId].profileOptions[0].stylistWidth
    const stylistDescription = stylistProfiles.stylists[arrayId].profileOptions[0].stylistDescription
    const modalDimensions = stylistProfiles.stylists[arrayId].profileOptions[0].modalDimensions
    const stylesImg = stylistProfiles.stylists[arrayId].profileOptions[0].stylesImg
    const stylesWidth = stylistProfiles.stylists[arrayId].profileOptions[0].stylesWidth
    const bookingComponent = stylistProfiles.stylists[arrayId].profileOptions[0].bookingComponent
    const bookingCompLink = stylistProfiles.stylists[arrayId].profileOptions[0].bookingCompLink
    const yelp = stylistProfiles.stylists[arrayId].profileOptions[0].socialMediaLinks[0].yelp
    const twitter = stylistProfiles.stylists[arrayId].profileOptions[0].socialMediaLinks[0].twitter
    const instagram = stylistProfiles.stylists[arrayId].profileOptions[0].socialMediaLinks[0].instagram
    const facebook = stylistProfiles.stylists[arrayId].profileOptions[0].socialMediaLinks[0].facebook
    const myAppointmentsLink = stylistProfiles.stylists[arrayId].profileOptions[0].myAppointmentsLink

    const useStyles = makeStyles(() => ({
        root: {
            "& > *": {
                marginTop: 2
            },
            "& .MuiPaginationItem-outlined": {
                backgroundColor: "rgba(184,221,243,0.66)"
            },
            "& .MuiPaginationItem-icon": {
                backgroundColor: "transparent",
                color: "#4f4f4f"
            },
            "& .Mui-selected": {
                borderColor: "rgb(2,47,73)"
            }
        }
    }));
    const classes = useStyles();

    const handleClickOpen = () => () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function changeShowServices(bool){
        setShowServices(bool)
    }

    function ShowDescriptionOrServices() {
        if(showServices) {
            return <BookingModule stylist={firstLastName} stylistId={stylistId} clearButton={clearButton}/>
        }else if(!showServices){
            return <DisplayStylistProfileMessage/>
        }
    }

    function getPageButton(buttonFor) {
        if(buttonFor === 'homePage') {
            return <UnderlinedButton onClick={handleClickOpen('paper')}>book now</UnderlinedButton>
        } else if(buttonFor === 'teamPage') {
            return <img src={stylistImage} style={{width: "100%"}} alt="" onClick={handleClickOpen('paper')}/>
        } else if(buttonFor === 'headerMenu'){
            // import BookNowIcon from "./icon/bookNowIcon";
            return <FadeButton onClick={handleClickOpen('paper')} ><AiOutlineCalendar size={iconSize} style={{ color: "primary" }} /></FadeButton>
        } else if(buttonFor === 'headerMenuWithText') {
            return <>
                <FadeButton onClick={handleClickOpen('paper')}>
                    <Grid container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          sx={{ mr: 1, width: 140 }}>
                        <Grid item sx={{ mr: 0.5, display: { xxs: 'none', md: 'none', lg: 'flex', xl: 'flex' }, color: "primary" }}>
                            book now
                        </Grid>
                        <Grid item>
                            <AiOutlineCalendar size={iconSize} style={{ color: "primary" }} />
                        </Grid>
                    </Grid>
                </FadeButton>
            </>
        } else if(buttonFor === 'plainText') {
            return <>
                <FadeButton onClick={handleClickOpen('paper')}>
                    <Grid container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          sx={{ mr: 1, width: 115 }}>
                        <Grid item sx={{ mr: 0.5, color: "primary" }}>
                            <Typography variant="p">book now</Typography>
                        </Grid>
                        <Grid item>
                            <AiOutlineCalendar size={iconSize} style={{ color: "primary" }} />
                        </Grid>
                    </Grid>
                </FadeButton>
            </>
        }
        return <FadeButton onClick={handleClickOpen('paper')}>BOOK NOW 1</FadeButton>
    }

    function GetBookingModuleOrLink(props) {
        const { bookingComponent, bookingCompLink, firstLastName } = props;
        if(!Check(customerId)) {
            return <LoginButton/>
        } else if(bookingComponent === 'link' && bookingCompLink !== ''){
            return <BlueUnderlinedButton href={bookingCompLink} target="_blank">book now</BlueUnderlinedButton>
        } else if(bookingComponent === 'text' && bookingCompLink !== ''){
            return <Typography variant="p">{bookingCompLink}</Typography>
        } else if(bookingComponent === 'module'){
            return <BlueUnderlinedButton onClick={() => changeShowServices(true)}>book now</BlueUnderlinedButton>
        }
        return null
    }

    function DisplayStylistPics(){
        if(useWindowDimensions(660)){
            return <>
                <Grid container>
                    <Grid item
                          sx={{ paddingRight: 1 }}
                    >
                        <img src={stylesImg} width={stylesWidth} alt=""/>
                    </Grid>
                </Grid>
            </>
        } else {
            return null
        }
    }

    // const SmallOrLargePagination = () => {
    //     let ret
    //     if(useWindowDimensions(660)){
    //         ret = "medium"
    //         return ret
    //     } else {
    //         ret = "small"
    //         return ret
    //     }
    // }

    function DisplayStylistImg(props){
        const { width } = props;
        return <>
            <img src={stylistImg} width={width} alt=""/>
        </>
    }

    function DisplayStylistNameTag(){
        return <>
            <Grid container
                  direction="row"
                  justifyContent="center"
                  display="flex"
            >
                <Grid item
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                >
                    <Typography variant="h3" sx={{ paddingRight: 2 }}>
                        {firstLastName}
                    </Typography>
                </Grid>
                <Grid item
                      alignItems="flex-end"
                      display="flex"
                      sx={{ 'minHeight': minStylistHeight }}
                >
                    <DisplayStylistImg width={stylistWidth} />
                </Grid>
                {/*<Grid item sx={{ position: "relative", left: "35px", bottom: "10px" }}*/}
                {/*      display='block'*/}
                {/*>*/}
                {/*    <Button onClick={handleClose}>X</Button>*/}
                {/*</Grid>*/}
            </Grid>
        </>
    }

    function ShowMyAppointments(){

        if(myAppointmentsLink === ''){
            return null
        }

        return <>
            {/*<BookingModal bookWith="Tina Christoff" minHeight={minHeight}/>*/}
            <FadeButton
                onClick={ () => window.open(myAppointmentsLink,'_blank')}
                sx={{ width: "240px"
                    , height: "40px"
                    , marginTop: {xxs: 2, xs: 2, sm: 2, md: 3 }
                    , fontSize: 20
                    , textTransform: 'lowercase'
                    , backgroundColor: '#b8ddf3'
                }}>my appointments
            </FadeButton>
        </>
    }

    function ShowYelp(props) {
        const itemWidth = props.width;
        const itemSize = props.size;
        if(twitter && twitter.length > 0){
            return <>
                <Grid item width={itemWidth}>
                    <TwtrIcon size={itemSize} link={twitter} />
                </Grid>
            </>
        }
        return null
    }

    function ShowTwitter(props) {
        const itemWidth = props.width;
        const itemSize = props.size;
        if(yelp && yelp.length > 0){
            return <>
                <Grid item width={itemWidth}>
                    <YpIcon size={itemSize} link={yelp} />
                </Grid>
            </>
        }
        return null
    }

    function ShowFacebook(props) {
        const itemWidth = props.width;
        const itemSize = props.size;
        if(facebook && facebook.length > 0){
            return <>
                <Grid item width={itemWidth}>
                    <FbIcon size={itemSize} link={facebook} />
                </Grid>
            </>
        }
        return null
    }

    function ShowInstagram(props) {
        const itemWidth = props.width;
        const itemSize = props.size;
        if(instagram && instagram.length > 0){
            return <>
                <Grid item width={itemWidth}>
                    <InstaIcon size={itemSize} link={instagram} />
                </Grid>
            </>
        }
        return null
    }

    function DisplayStylistFooter(props){
        const itemWidth = props.width;
        const itemSize = props.size;

        return <>
            <Grid container direction="column">
                <Grid item display="flex">
                    <Grid container
                          direction="row"
                          sx={{ pt: 1 }}
                    >
                        <Grid item
                              display="flex"
                              alignItems="flex-start"
                              justifyContent="flex-start"
                              sx={{ pb: 1 }}
                        >
                            {/*<BlueUnderlinedButton>book now</BlueUnderlinedButton>*/}

                            <GetBookingModuleOrLink
                                bookingComponent={bookingComponent}
                                bookingCompLink={bookingCompLink}
                                firstLastName={firstLastName}
                                minHeight={minHeight}
                            />

                        </Grid>

                        <Grid item
                              display="block"
                              alignItems="flex-start"
                              justifyContent="flex-start"
                        >
                            {/*Footer Icons*/}
                            <Grid container
                                  direction="row"
                                  justifyContent="flex-end"
                                  alignItems="flex-end"
                                  sx={{
                                      display: { xxs: 'flex', md: 'flex' },
                                      paddingTop: 1,
                                      paddingRight: 3
                                  }}
                            >
                                <ShowYelp width={itemWidth} size={itemSize}/>
                                <ShowTwitter width={itemWidth} size={itemSize}/>
                                <ShowInstagram width={itemWidth} size={itemSize}/>
                                <ShowFacebook width={itemWidth} size={itemSize}/>
                            </Grid>
                        </Grid>

                        <Grid item
                              display="flex"
                              alignItems="flex-start"
                              justifyContent="flex-start"
                              sx={{ position: "relative", bottom: 18 }}
                        >
                            <ShowMyAppointments/>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    }

    // Default modal dimensions = {height: 146, maxWidth: 320}
    function DisplayStylistProfileMessage(){
        return <>
            <Grid item>
                <Grid container>
                    <Grid item sx={modalDimensions}>
                        <Typography variant="p">
                            {stylistDescription}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <DisplayStylistFooter width={40} size={18} />
            </Grid>
        </>
    }

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    const handleChange = (event, value) => {
        setShowServices(false)
        if(value > numStylists){
            return null
        }
        setArrayId(value -1);
        setNewPage(value)
    };

    function clearButton(showHide) {
        if(showHide === 'show'){
            //console.log("SHOW")
            setShowServices(true)
        }else if(showHide === 'hide'){
            //console.log("HIDE")
            setShowServices(false)
        }
        return null
    }

    return (
        <>
            {getPageButton(buttonFor)}
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth={'sm'}
            >

                <DialogTitle
                    sx={{
                        backgroundColor: '#fff',
                        height: "1px",
                        zIndex: 10000
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} />
                        <Box>
                            <IconButton onClick={handleClose}>
                                X
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers={false}
                               sx={{
                                   backgroundColor: '#fff',
                                   minHeight: {minHeight}
                               }}
                >

                    <Grid container direction="row">
                        <Grid item>
                            <DisplayStylistPics/>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <DisplayStylistNameTag/>
                                </Grid>

                                <ShowDescriptionOrServices/>

                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
                {/*<BookingModal bookWith="Tina Christoff"/>*/}

                <DialogActions
                    sx={{
                        backgroundColor: '#fff',
                        justifyContent: "center",
                        alignItems: "center",
                        display: showPag
                    }}
                >
                    <div className={classes.root} style={{flex: '1 1 1'}}>
                        <Stack spacing={2}>
                            <Pagination
                                count={numStylists}
                                siblingCount={0}
                                page={newPage}
                                onChange={handleChange}
                                variant="outlined"
                                color="primary"
                                value={arrayId}
                                size='medium'
                            />
                        </Stack>
                    </div>

                    {/*<Button onClick={handleClose} size={SmallOrLargePagination()}>Close</Button>*/}
                </DialogActions>
            </Dialog>
        </>
    );
}



