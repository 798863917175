import Api from "../callApi/getApi";
import Check from "../auth/check";
import Redirect from "../helper/redirect";
import {Typography} from "@mui/material";
import getLocalStorage from "../localStorage/getLocalStorage"

export default function AccountUpdate(props) {
    const {validData, firstName, lastName, email, phone, addressLine1, addressLine2, addressCity, addressState, addressZip} = props;
    let query = '';
    const customerId = getLocalStorage("customerId")
    // console.log("validData: " + validData)
    if(validData === true && Check && customerId.trim().length > 0) {
        query = "customerId=" + customerId.trim() +
            '&firstName=' + firstName +
            '&lastName=' + lastName +
            '&email=' + email +
            '&phone=' + phone +
            '&addressLine1=' + addressLine1 +
            '&addressLine2=' + addressLine2 +
            '&addressCity=' + addressCity +
            '&addressState=' + addressState +
            '&addressZip=' + addressZip;

        const {ret, isLoading, isError} = Api("/api/customerUpdate?" + query)

        if (isLoading) return <Typography variant='p'>loading...</Typography> // <Spinner />
        if (isError) return <Typography variant='p'>data load error</Typography> // <Error />
        if (ret){
            Redirect('/account')
        }
        return null
    } else {
        return null
    }



}
