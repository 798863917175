import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {Typography} from "@mui/material";

export default function ConfirmAppointment(props) {
    const {handleConfirm, doOpenConfirm, serviceId, stylistId, dateTime, setIsDisabled} = props
    const [open, setOpen] = useState(false);
    const styles = { color: '#000000', backgroundColor: '#ffffff' }

    useEffect(() => {
        setOpen(doOpenConfirm);
    },[doOpenConfirm])

    function handleCancelButton() {
        setOpen(false);
        handleConfirm(false);
        setIsDisabled(false);
    }

    function handleConfirmButton() {
        setOpen(false);
        handleConfirm(true);
        setIsDisabled(true);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCancelButton}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"
                    sx={styles}
                >
                    Confirm Appointment
                </DialogTitle>
                <DialogContent
                    sx={styles}
                >

                    <Typography variant="h4">Date: {new Date(dateTime).toDateString()}</Typography>
                    <Typography variant="h4">Time: {new Date(dateTime).toLocaleTimeString('en-US')}</Typography>

                </DialogContent>
                <DialogActions
                    sx={styles}
                >
                    <Button onClick={handleCancelButton}>Cancel</Button>
                    <Button onClick={handleConfirmButton} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
