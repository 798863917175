import Api from "../callApi/getApi";
import {Typography} from "@mui/material";

export default function ProductImg(props) {
    const imgId = props.imgId;
    const style = (props.style ? props.style : { maxWidth: "160px", maxHeight: "160px", border: "1px solid #ccc" } )
    //console.log("ImageId: " + JSON.stringify(imgId))
    const {ret, isLoading, isError} = Api("/api/image?imgId=" + imgId)

    if (isLoading) return <Typography variant="h2" sx={{paddingLeft: 2}}>loading...</Typography> // <Spinner />
    if (isError) return <Typography variant="h2" sx={{paddingLeft: 2}}>data load error...</Typography> // <Error />

    return <>
        <img src={ret} style={style} alt="" />
    </>
}
