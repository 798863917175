const CryptoJS = require('crypto-js');

function decrypt(param){
    const dec = CryptoJS.AES.decrypt(param, '^xqhtd&gp$rmw#77');
    const originalText = dec.toString(CryptoJS.enc.Utf8);
    return originalText
}

module.exports = decrypt


