import { useAuth0 } from "@auth0/auth0-react";
// import SignOut from "../components/auth/signout";
// import AccountForm from "../components/sections/accountForm";

export default function LogOut(props) {

    function getAccountState(){
        if(props.validatedFormat){
            return (
                <>
                    {/* Remove all data on signout */}
                    {/*<SignOut />*/}
                    {/*<SignOut/>*/}
                    <LogoutButton />
                </>
            )
        }else{
            return null
        }
    }

    return getAccountState();

};


const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <button onClick={() => logout({ returnTo: window.location.origin })}>
            Log Out
        </button>
    );
};
