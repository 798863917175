import PartialBlueUnderlinedTitle from "../components/button/partialBlueUnderlinedTitle";
import {Grid, Typography} from "@mui/material";
import {custom} from "../components/custom";
import Container from "@mui/material/Container";
import MenuButton from "../components/button/menuButton";
import { useCart } from "react-use-cart";
import {Link} from "react-router-dom";
import FadeButton from "../components/button/fadeButton";
import ProductImage from "../../src/components/parse/productImage";
import AddToCartButton from "../components/button/addToCartButton";
// import PriceDeserialize from "../components/parse/priceDeserialize";
import BlueUnderlinedButton from "../components/button/blueUnderlinedButton";
import React, {useState} from "react";
import Redirect from "../components/helper/redirect";
import PriceDecimalize from "../components/parse/priceDecimalize";
import {LoginButton} from "../components/auth/loginButton";
import Check from "../components/auth/check";
import options from "../components/callApi/corsApi";
import getLocalStorage from "../components/localStorage/getLocalStorage"


const squareLocationId = process.env.REACT_APP_SQUARE_LOCATION_ID

const textColor = custom.color.contact
// const plusColor = custom.color.productPlus

const customerId = getLocalStorage("customerId")

function Cart() {

    //   Setting button text
    const [buttonText, setButtonText] = useState("checkout...");

    const doCheckOut = async (customerId,items) => {
        const fetchCustomerInfo = new Request("/api/customer?customerId=" + customerId)
        await fetch(fetchCustomerInfo,options)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((response) => {
                if (!response.customer) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                // console.log("Customer Response: " + JSON.stringify(response.customer) + " " + items);
                return fetchPaymentLink(items,response.customer)
            })
            .then((response) => {
                // console.log("response: " + JSON.stringify(response));
                if (!response) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((response) => {
                if(response){
                    Redirect(response.redirectTo)
                    // console.log(JSON.stringify(response))
                    setButtonText("checkout...")
                }
            })
        ;
    }

    const fetchPaymentLink = async (items,customerData) => {
        //console.log("customerData: " +  JSON.stringify(customerData))
        const res = await fetch("/api/returnPaymentLink", {
            body: JSON.stringify({
                customerId: customerId,
                locationId: squareLocationId,
                cartLineItems: items,
                customerInfo: customerData,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            options
        });
        const { error } = await res;
        if (error) {
            console.log(error);
            return;
        }
        return res;
    }

    function ItemUpdateQuantity(props) {
        return <>
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
            >
                <Grid item
                      alignItems="center"
                      justifyContent="center"
                >
                    <AddToCartButton
                        size={10}
                        color="black"
                        icon="minus"
                        onClick={() => props.updateItemQuantity(props.item.id, props.item.quantity - 1)}
                    >
                    </AddToCartButton>
                </Grid>
                <Grid item
                      alignItems="center"
                      justifyContent="center"
                >
                    <Typography
                        variant="p"
                        textAlign="center"
                        style={{ fontSize: "22px", padding: "10px" }}
                    >
                        {props.item.quantity}
                    </Typography>
                </Grid>
                <Grid item
                      alignItems="center"
                      justifyContent="center"
                >
                    <AddToCartButton
                        size={10}
                        color="black"
                        icon="plus"
                        onClick={() => props.updateItemQuantity(props.item.id, props.item.quantity + 1)}
                    >
                    </AddToCartButton>
                </Grid>
            </Grid>
        </>
    }

    function ItemPrice(props) {
        const price = props.itemPrice
        return <>
            <Grid container
                  direction={{ xxs: "column", xs: "column", sm: "row", md: "row" }}
                  justifyContent={{ xxs: "flex-start", xs: "flex-start", sm: "flex-start", md: "center" }}
                  alignItems="stretch">
                <Grid item>
                    <Typography
                        sx={{ color: textColor }}
                        fontSize={{xxs: 10, xs: 14, sm: 16, md: 18}}
                        margin={{xxs: 2, xs: 2, sm: 1, md: 1}}
                        paddingTop={{ xxs: 0, xs: 0, sm: 1, md: 2 }}
                        fontWeight={500}
                        width="100%"
                        variant="p"
                        style={{margin: 0, textAlign: "left", fontSize: 18 }}
                    >
                        ${PriceDecimalize(price)}
                    </Typography>
                </Grid>
            </Grid>
        </>
    }

    function ItemImage(props) {
        const updateItemQuantity = props.updateItemQuantity
        const item = props.item
        return <>
            <Grid container
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
            >
                <Grid item
                      display="flex"
                      justifyContent="center"
                      alignItems="stretch">
                    {/*<img src="./mock/product1.png" alt="" style={{ width: "100px", border: "1px solid #000" }}/>*/}
                    <ProductImage imgId={item.image} style={{ width: "100px", border: "1px solid #000" }}/>
                </Grid>

                <Grid
                    item
                    display={{ xxs: "block", xs: "block", sm: "block", md: "none" }}
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <ItemUpdateQuantity updateItemQuantity={updateItemQuantity} item={item} />
                </Grid>
            </Grid>
        </>
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setButtonText("processing...")

        const items = document.getElementById("data").value;

        let isValidForm = true;

        // console.log("items: " + items);

        if (isValidForm) {
            await doCheckOut(customerId,items)
        }
        //console.log("Processing done");
    };

    function checkoutButton(items) {

        if(Check(customerId)){
            return <>
                <form onSubmit={handleSubmit}>
                    <BlueUnderlinedButton
                        type="submit"
                        sx={{ width: {xxs: "340px", xs: "340px", sm: "228px", md: "228px" }
                            , height: "40px"
                            , marginTop: {xxs: 0, xs: 0, sm: 3, md: 3 }
                            , marginBottom: {xxs: 2, xs: 2, sm: 2, md: 0 }
                            , marginLeft: 1
                            , fontSize: 30
                            , textTransform: 'lowercase'
                        }}>
                        {buttonText}
                    </BlueUnderlinedButton>
                    <input type="hidden" value={JSON.stringify(items)} id="data"/>
                    {/*<input type="hidden" value={JSON.stringify(customerData)} id="customer"/>*/}
                </form>
            </>
        } else {
            return <LoginButton/>
        }
    }

    function ShowCart() {

        const {
            isEmpty,
            totalItems,
            items,
            removeItem,
            updateItemQuantity,
            cartTotal,
        } = useCart();

        if (isEmpty) return (
            <>
                <Grid container
                    direction="column"
                >
                    <Grid item>
                        <Typography variant="p" sx={{paddingLeft: 2}}>Your cart is empty.</Typography>
                    </Grid>
                    <Grid item>
                        <FadeButton
                            component={Link}
                            to={'/shop'}
                            sx={{ color: textColor
                                , width: {xxs: "340px", xs: "340px", sm: "200px", md: "200px" }
                                , height: "40px"
                                , marginTop: {xxs: 3, xs: 3, sm: 3, md: 3 }
                                , marginBottom: {xxs: 2, xs: 2, sm: 2, md: 3 }
                                , marginLeft: 1
                                , fontSize: 20
                                , textTransform: 'lowercase'
                                , backgroundColor: '#b8ddf3'
                            }}>
                            go shop...
                        </FadeButton>
                    </Grid>
                </Grid>
            </>
        );

        return (
            <>
                <PartialBlueUnderlinedTitle
                    disabled
                    alt="Shop AftonBlu Products"
                    sx={{ color: textColor
                        , fontSize: {xxs: 28, xs: 28, sm: 28, md: 30 }
                        , fontWeight: 300
                        , width: {xxs: "340px", xs: "340px", sm: "400px", md: "430px" }
                        , letterSpacing: {xxs: 4, xs: 4, sm: 7.5, md: 7.5 }
                        , paddingTop: 4 }}
                >
                    shopping cart ({totalItems})
                </PartialBlueUnderlinedTitle>

                {/*Header - Item Qty Price*/}
                <Grid container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      sx={{ paddingLeft: {xxs: 1, xs: 2, sm: 5, md: 5 }
                          , paddingRight: {xxs: 1, xs: 2, sm: 5, md: 5 }
                          , display: { xxs: 'none', sm: 'none', md: 'flex', lg: 'flex' }
                          , border: "0px solid #000"
                          , maxWidth: "1200px"
                      }}>
                    {/* START ROW */}
                    <Grid item
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          sx={{ width: "400px" }}>
                        {/*  None  */}
                        {/*<span>Imqty</span>*/}
                    </Grid>
                    <Grid item
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          sx={{ width: "340px" }}>
                        <Typography variant="span">
                            Item
                        </Typography>
                    </Grid>
                    <Grid item
                          display="flex"
                          justifyContent="center"
                          alignItems="stretch"
                          sx={{ width: "130px" }}>
                        <Typography variant="span">
                            Qty
                        </Typography>
                    </Grid>
                    <Grid item
                          display="flex"
                          justifyContent="center"
                          alignItems="stretch"
                          sx={{ width: "80px" }}>
                        <Typography variant="span">
                            Price
                        </Typography>
                    </Grid>
                </Grid>

                {/* Products - all screens */}
                    {items.map((item) => (
                        <span key={item.id}>

                            <Grid container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="stretch"
                                  sx={{ paddingLeft: {xxs: 0, xs: 0, sm: 1, md: 10 }
                                      , paddingRight: {xxs: 1, xs: 1, sm: 1, md: 1 }
                                      , paddingTop: 1
                                      , paddingBottom: 1
                                      , marginBottom: 2
                                      , display: { xs: 'flex', sm: 'flex', md: 'block', lg: 'block' }
                                      , border: "1px solid #000"
                                      , maxWidth: "1200px"
                                  }}>

                                <Grid item
                                      display={{ xxs: "block", xs: "block", sm: "block", md: "none" }}
                                >
                                    <ItemImage updateItemQuantity={updateItemQuantity} item={item}/>
                                </Grid>

                                <Grid item>
                                {/* Items in ShowCart */}
                                    <Grid container
                                          direction={{ xxs: "column", xs: "column", sm: "column", md: "row" }}
                                          justifyContent="flex-start"
                                          alignItems="stretch"
                                          sx={{ paddingLeft: {xxs: 1, xs: 2, sm: 5, md: 5 }
                                              , paddingRight: {xxs: 1, xs: 2, sm: 5, md: 5 }
                                              , display: { xxs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }
                                              , border: "0px solid #000"
                                              , maxWidth: "1200px"
                                          }}>
                                            {/* START ROW */}
                                            <Grid
                                                item
                                                sx={{ width: "180px" }}
                                                display={{ xxs: "none", xs: "none", sm: "none", md: "block" }}
                                            >
                                                <ItemImage updateItemQuantity={updateItemQuantity} item={item}/>
                                            </Grid>

                                            <Grid item
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  alignItems="stretch"
                                                  width={{ xxs: "200px", xs: "224px", sm: "400px", md: "480px" }}>
                                                <Typography
                                                    sx={{ color: textColor }}
                                                    fontSize={{xxs: 10, xs: 14, sm: 16, md: 18}}
                                                    fontWeight={500}
                                                    variant="p"
                                                    style={{textAlign: "left"}}
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Grid>

                                            <Grid item
                                                  display={{ xxs: "flex", xs: "flex", sm: "flex", md: "none" }}
                                                  paddingTop={2}
                                            >
                                                <ItemPrice itemPrice={item.price}/>
                                            </Grid>

                                            <Grid item
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  alignItems="stretch"
                                                  sx={{ width: "130px" }}>

                                                {/* Qty & Delete Group ROW - larger screens */}
                                                <Grid container
                                                      direction={{ xxs: "column", xs: "column", sm: "row", md: "row" }}
                                                      justifyContent="flex-end"
                                                      alignItems="stretch"
                                                      display={{ xxs: "none", xs: "none", sm: "none", md: "block" }}>
                                                    <Grid item>
                                                        <Typography
                                                            sx={{ color: textColor }}
                                                            fontSize={{xxs: 10, xs: 14, sm: 16, md: 18}}
                                                            margin={{xxs: 2, xs: 2, sm: 1, md: 1}}
                                                            paddingTop={{ xxs: 0, xs: 0, sm: 1, md: 2 }}
                                                            fontWeight={500}
                                                            width="100%"
                                                            variant="p"
                                                            style={{margin: 0, textAlign: "left", fontSize: 18 }}>
                                                                <ItemUpdateQuantity updateItemQuantity={updateItemQuantity} item={item} />
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            variant="p"
                                                            style={{margin: 0, textAlign: "left", paddingLeft: 18}}
                                                        >
                                                            <MenuButton sx={{ fontSize: 12, marginTop: 4 }} onClick={() => removeItem(item.id)}>Delete</MenuButton>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                            <Grid item
                                                  justifyContent="flex-start"
                                                  alignItems="stretch"
                                                  sx={{ width: "80px" }}
                                                  display={{ xxs: "none", xs: "none", sm: "none", md: "block" }}
                                            >
                                                  <ItemPrice itemPrice={item.price}/>
                                            </Grid>
                                        </Grid>
                                </Grid>
                                {/**/}
                            </Grid>
                        </span>
                    ))}


                <Grid
                    container
                    direction="row"
                    alignItems="stretch"
                    justifyContent="space-between"
                    maxWidth="1200px"
                >
                    <Grid item>
                        <FadeButton
                            component={Link}
                            to={'/shop'}
                            sx={{ color: textColor
                                , width: {xxs: "340px", xs: "340px", sm: "200px", md: "200px" }
                                , height: "40px"
                                , marginTop: {xxs: 0, xs: 0, sm: 3, md: 3 }
                                , marginBottom: {xxs: 2, xs: 2, sm: 2, md: 0 }
                                , marginLeft: 1
                                , fontSize: 20
                                , textTransform: 'lowercase'
                                , backgroundColor: '#b8ddf3'
                            }}>
                            continue shopping...
                        </FadeButton>
                    </Grid>
                    <Grid item>
                        {checkoutButton(items)}
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    maxWidth="1200px"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                >
                    <Grid item sx={{ paddingRight: 3 }}>
                        <Typography variant="h2">Sub-total: ${PriceDecimalize(cartTotal)}</Typography>
                    </Grid>
                </Grid>

            </>
        );
    }

    return (
        <Container maxWidth="100%">

            <ShowCart/>

        </Container>
    )
};

export default Cart;
