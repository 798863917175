// useWindowDimensions.js

import {useState, useEffect, useCallback} from 'react';

export default function useWindowDimensions(dim) {

    const hasWindow = typeof window !== 'undefined';



    function useWindowDimensions(dim) {
        // const height = hasWindow ? window.innerHeight : null;
        const getDim = useCallback( () => {
            const width = hasWindow ? window.innerWidth : null;
            if(width <= dim){
                return false;
            } else {
                return true;
            }
        },[dim]);
        getDim();
    }

    const [windowDimensions, setWindowDimensions] = useState(useWindowDimensions(dim));

    useEffect(() => {
        if (hasWindow) {
            function useHandleResize() {
                setWindowDimensions(useWindowDimensions(dim));
            }

            window.addEventListener('resize', useHandleResize);
            return () => window.removeEventListener('resize', useHandleResize);
        }
    }, [hasWindow,dim,useWindowDimensions]);

    return windowDimensions;
}
