import getLocalStorage from "../localStorage/getLocalStorage"

export default function Check(){

    let validatedFormat = false

    const storedCustomerId = getLocalStorage("customerId");
    const storedFirstName = getLocalStorage("firstName");
    const letterNumber = /^[0-9A-Z]+$/;
    if(storedCustomerId && storedFirstName && storedFirstName.length > 0 ){
        const storedCustomerIdLength = storedCustomerId.length
        validatedFormat = storedCustomerId.match(letterNumber) && storedCustomerIdLength === 26;
    }
    return validatedFormat

}

