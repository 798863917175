import {useAuth0} from "@auth0/auth0-react";
import BlueUnderlinedButton from "../button/blueUnderlinedButton";

export const LoginButton = (props) => {
    const { loginWithRedirect } = useAuth0();

    return <>
        <BlueUnderlinedButton
            onClick={() => loginWithRedirect(props)}
            sx={{ width: {xxs: "140px", xs: "140px", sm: "140px", md: "140px" }
                , height: "40px"
                , marginTop: {xxs: 0, xs: 0, sm: 0, md: 0 }
                , marginBottom: {xxs: 0, xs: 0, sm: 0, md: 0 }
                , marginLeft: 0
                , fontSize: 30
                , textTransform: 'lowercase'
            }}>
            log in
        </BlueUnderlinedButton>
    </>

};
