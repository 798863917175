import useSWR from "swr";
import options from "./corsApi";

const fetcher = (...args) => fetch(...args,options).then(res => res.json())

export default function Api(uri1) {
    const { data, error } = useSWR(uri1, fetcher)
    if(error){
        // console.log("URI Called: " + uri1)
        console.log("error: " + error)
        return {
            ret: {},
            isLoading: !error && !data,
            isError: error
        }
    }else{
        // console.log("data: " + data)
    }
    return {
        ret: data,
        isLoading: !error && !data,
        isError: error
    }
}
