import { Auth0Provider } from "@auth0/auth0-react";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {CartProvider} from "react-use-cart";

ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={process.env.REACT_APP_AUTH0_CALLBACK_URL}
        audience={process.env.REACT_APP_AUTH0_TENANT_API}
        scope="read:current_user update:current_user_metadata"
        logoutUrl={process.env.REACT_APP_AUTH0_TENANT_LOGOUT_URL}
    >
        {/*{window.location.origin}*/}
        {/*<React.StrictMode>*/}
            <BrowserRouter>
                <CartProvider>
                    <App />
                </CartProvider>
            </BrowserRouter>
        {/*</React.StrictMode>,*/}
    </Auth0Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
