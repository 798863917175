import {ImPlus,ImMinus} from "react-icons/im";
import IconButton from "@mui/material/IconButton";

export default function addToCartButton(props) {
    const size = props.size;
    const color = props.color;
    // const productSpecs = props.specs;
    const go = props.onClick;
    // const bgColor = props.bgColor;
    const icon = props.icon;

    // console.log("specs: " + JSON.stringify(productSpecs))

    return <>
        <IconButton
            onClick={go}
        >
            {( icon==='plus' ? <ImPlus size={size} color={color}  /> : <ImMinus size={size} color={color}  /> )}
        </IconButton>
    </>
}




