import React, {useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Header from "./components/header";
import {ThemeProvider} from "@emotion/react";
import Shop from "./pages/shop";
import Team from "./pages/team";
import Account from "./pages/account";
import BookNow from "./pages/booknow";
import Cart from "./pages/cart";
import Footer from "./components/footer";
import {theme} from "./components/theme";
import CheckAuth from "./components/auth/check";
import LogOut from "./pages/logout";
import Profile from "./pages/profile";
import Welcome from "./pages/welcome";
import Complete from "./pages/complete";
import ThankYou from "./pages/thankYou";
import Goodbye from "./pages/goodbye";
import getLocalStorage from "./components/localStorage/getLocalStorage";
import AprilFravel from "./components/stylists/aprilFravel"

function App() {

    const [welcomeMessage, setWelcomeMessage] = useState(null)
    const [validatedFormat, setValidatedFormat] = useState(false);
    let checkIt = useRef(false);
    checkIt.current = CheckAuth()
    let welcomePost = useRef(false);
    welcomePost.current = CheckAuth()

    useEffect(() => {
        setValidatedFormat(checkIt.current)
    },[checkIt]);

    useEffect(() => {
        welcomePost.current ? setWelcomeMessage(getLocalStorage("firstName")) : setWelcomeMessage(null)
    },[welcomePost]);

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Header welcome={welcomeMessage} />
                <Routes>
                    <Route path='/home/:bookNow' element={<Home/>} />
                    <Route path='/about' element={<About/>} />
                    {/* Note how these two routes are ordered. The more specific
                path="/contact/:id" comes before path="/contact" so that
                route will render when viewing an individual contact */}
                    {/*<Route path="/contact/:id">*/}
                    {/*    <Contact />*/}
                    {/*</Route>*/}
                    <Route path='/contact' element={<Contact join={0} key={Math.random()} />} />
                    <Route path='/join' element={<Contact join={1} key={Math.random()} />} />
                    <Route path='/shop' element={<Shop/>} />
                    <Route path='/team' element={<Team/>} />
                    <Route path='/account' element={<Account
                        validatedFormat={validatedFormat}
                    />} />
                    <Route path='/book-now' element={<BookNow
                        validatedFormat={validatedFormat}
                    />} />
                    <Route path='/cart' element={<Cart/>} />
                    <Route path='/aprilfravel' element={<AprilFravel/>} />

                    {/* If none of the previous routes render anything,
                    this route acts as a fallback.
                    Important: A route with path="/" will *always* match
                    the URL because all URLs begin with a /. So that's
                    why we put this one last of all */}

                    {/* PROFILE */}
                    <Route path='/profile' element={<Profile/>} />

                    {/* WELCOME */}
                    <Route path='/welcome' element={<Welcome/>} />

                    {/* APPOINTMENT COMPLETE */}
                    <Route path='/thank-you' element={<ThankYou/>} />

                    {/* ACCOUNT DELETE COMPLETE */}
                    <Route path='/goodbye' element={<Goodbye/>} />

                    {/* PAYMENT COMPLETE */}
                    <Route path='/complete' element={<Complete/>} />

                    {/* AUTH */}
                    {/*<Route path='/sign-in' element={<SignIn/>} />*/}
                    {/*<Route path='/sign-out' element={<SignOut/>} />*/}

                    {/*<Route path='/login' element={<LogIn*/}
                    {/*    setWelcomeMessage={setWelcomeMessage}*/}
                    {/*    setValidatedFormat={setValidatedFormat}*/}
                    {/*    validatedFormat={validatedFormat}*/}
                    {/*    welcomeMessage={welcomeMessage}*/}
                    {/*/>} />*/}
                    <Route path='/logout' element={<LogOut/>} />

                    <Route path='/*' element={<Home/>} />

                </Routes>
                <Footer />

            </ThemeProvider>
        </div>
    );
}

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById("root")
);

export default App;
