import { FaYelp } from 'react-icons/fa';
import FadeButton from "../button/fadeButton";

export default function YpIcon(props) {

    const { size, color, link } = props

    return (
        <FadeButton sx={{ width: 20 }} href={link} target={'_blank'}>
            <FaYelp sx={{pt: 0.5 }} size={size} style={{ color: color }} />
        </FadeButton>
    )

}
