import AccountForm from "../components/sections/accountForm";
import {LoginButton} from "../components/auth/loginButton";
import {Grid, Typography} from "@mui/material";

export default function Account(props) {

    function getAccountState(){
        if(!props.validatedFormat){
            return (
                <Grid container display="flex" justifyContent="center" alignItems="center" direction="column">
                    <Grid item>
                        <Typography variant="h1"> Please click the login button below:</Typography>
                    </Grid>
                    <Grid item>
                        <LoginButton/>
                    </Grid>
                </Grid>
            )
        }else if(props.validatedFormat){

            return (
                <>
                    <AccountForm />

                </>
            )
        }else{
            return null
        }
    }

    return getAccountState();

};
