import Api from "../callApi/getApi";
import Check from "../auth/check";
import getLocalStorage from "../localStorage/getLocalStorage"

export default function AccountInfo() {
    // const customerId = props.customerId;
    let query = '';
    const customerId = getLocalStorage("customerId")
    if(Check && customerId.trim().length > 0){
        query = "customerId=" + customerId.trim()
    }

    const {ret, isLoading, isError} = Api("/api/customer?" + query)
    const noUserExists = {customer: {id: -2} }
    let customerData

    if (isLoading) return "loading..." // <Spinner />
    if (isError) return "data load error..." // <Error />
    if (ret) customerData = (ret.customer ? ret.customer : noUserExists.customer)

    return customerData

}
