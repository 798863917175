import Api from "../callApi/getApi";
import {FormControl, Grid, InputLabel, MenuItem, Select, Slider, Typography} from "@mui/material";
import {useState} from "react";
import FadeButton from "../button/fadeButton";
import BlueUnderlinedButton from "../button/blueUnderlinedButton";
import ServiceDateTimes from "../parse/serviceDateTimes";
import ConfirmAppointment from "../modals/confirmAppointment";
import BigIntDeserialize from "../parse/bigIntDeserialize";
import Redirect from "../helper/redirect";
import * as React from "react";
import getLocalStorage from "../localStorage/getLocalStorage"

export default function BookingModule(props) {
    const customerId = getLocalStorage("customerId");
    const { clearButton, stylist, stylistId } = props
    const [serviceId, setServiceId] = useState('');
    const defaultDisabledColor = '#eae9e9'
    const [disabledColor,setDisabledColor] = useState(defaultDisabledColor);
    const [isDisabled,setIsDisabled] = useState(true);
    const [selectedServiceDateTime,setSelectedServiceDateTime] = useState('')
    const [buttonText,setButtonText] = useState('schedule it')
    const [sliderWeeks,setSliderWeeks] = useState(4)
    const [appointmentData,setAppointmentData] = useState('')
    const [confirmed,setConfirmed] = useState(false)
    const [doOpenConfirm,setDoOpenConfirm] = useState(false)
    const [mainDisplay, setMainDisplay] = useState("flex")
    const [serviceVariationVersion, setServiceVariationVersion] = useState('')

    // TODO: If a stylist is using the BookingModule for Services, their API settings needs to be set here
    let apiUrl
    apiUrl = (stylist === 'Tina Christoff' ? '/api/products?type=ITEM&physical=false' : '')
    let confirmApiUrl
    confirmApiUrl = (
        stylist === 'Tina Christoff'
            ? '/api/booking?create=' + confirmed
            + '&data=' + appointmentData
            + '&startAt=' + selectedServiceDateTime
            + '&customerId=' + customerId
            + '&serviceVariationVersion=' + BigIntDeserialize(serviceVariationVersion)
            : '')

    if (!apiUrl || apiUrl === ''){
        return null
    }

    const handleConfirm = (bool) => {
        setConfirmed(bool)
        setDoOpenConfirm(false)

        if(bool === true){
            setMainDisplay("none")
            //console.log("Confirmed: " + bool)
        }

        return null
    }

    const handleChange = (event) => {
        setServiceId(event.target.value);
        if(event.target.value !== '' && event.target.value !== 'None'){
            //console.log("serviceName: " + serviceId)
            setDisabledColor('#ffffff');
            setIsDisabled(true);
        }else{
            setDisabledColor(defaultDisabledColor);
            setIsDisabled(true);
        }
    };

    const handleDateChange = (event) => {
        // setServiceDateTime(event.target.value);
        setSelectedServiceDateTime(event.target.value.startAt)
        //console.log("Service Id Selected: " + serviceId)
        //console.log("Date Selected: " + event.target.value.startAt)
        setAppointmentData(JSON.stringify(event.target.value.appointmentSegments[0]))
        setServiceVariationVersion(event.target.value.appointmentSegments[0].serviceVariationVersion)
        setIsDisabled(false);
        // console.log("disabledColor: " + event.target.value)
    };

    const { ret, isLoading, isError } = Api(apiUrl)
    if (isLoading) return <Typography variant="h2" sx={{paddingLeft: 2}}>loading...</Typography> // <Spinner />
    if (isError) return <Typography variant="h2" sx={{paddingLeft: 2}}>data load error...</Typography> // <Error />

    const handleSubmit = async (e) => {
        e.preventDefault();

        setButtonText("scheduling...")
        setIsDisabled(true)

        const dateTime = document.getElementById("selectedDateTime").value;
        const serviceId = document.getElementById("selectedService").value;
        const stylistId = document.getElementById("selectedStylist").value;
        const appointmentObject = document.getElementById("selectedAppointment").value;


        let isValidForm = false;

        //console.log("dateTime: " + dateTime);
        //console.log("serviceId: " + serviceId);
        //console.log("stylistId: " + stylistId);
        //console.log("appointmentObject: " + appointmentObject);

        if (!isValidForm) {
            if(new Date(dateTime) && serviceId.length > 0 && stylistId.length > 0 && typeof appointmentObject === 'string'){
                isValidForm = true
            }
        }

        if(isValidForm){
            setButtonText("processing")
            setDoOpenConfirm(true)
            //console.log("Processing done");
        }


        setButtonText("schedule it")
    };

    const SliderUpdate = (e) => {
        e.preventDefault()
        setSliderWeeks(e.target.value)
        setSelectedServiceDateTime('')
        return null
    }

    const ConfirmationProcessingMessage = () => {
        if(confirmed === true && mainDisplay === 'none') {
        } else {
            return ''
        }

        const { ret, isLoading, isError } = Api(confirmApiUrl)

        if (isLoading) {
            return <>
                <Grid container direction="column" display="flex">
                    <Typography variant="p">Processing Appointment...</Typography>
                </Grid>
            </>
        }

        if (isError) {
            return <>
                <Grid container direction="column" display="flex">
                    <Typography variant="p">Processing Error. Please call the salon to schedule your appointment.</Typography>
                </Grid>
            </>
        }

        if (ret.category && ret.code && ret.detail) {
            return <>
                <Grid container direction="column" display="flex" alignItems="flex-start" justifyContent="flex-start">
                    <Grid item>
                        <Typography variant="p" display="flex">
                            {JSON.stringify(ret.category)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="p" display="flex">
                            {JSON.stringify(ret.code)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="p" display="flex">
                            {JSON.stringify(ret.detail)}
                        </Typography>
                    </Grid>
                </Grid>
            </>
        } else if(ret) {
            //console.log("Appointment Scheduled: " + JSON.stringify(ret))
            Redirect('/thank-you')
            return null
        }

    }

    return <>
        <Grid item>
            <ConfirmationProcessingMessage/>
            <Grid container direction="column" display={mainDisplay}>
                <Grid item sx={{ padding: 1 }}>
                    <Typography variant="p">{sliderWeeks} Weeks Out:</Typography>
                    <Slider size="small"
                            defaultValue={4}
                            value={sliderWeeks}
                            aria-label="small"
                            valueLabelDisplay="auto"
                            sx={{ color: 'tertiary.main' }}
                            min={0}
                            max={12}
                            onChange={SliderUpdate}
                    />
                </Grid>
                <Grid item>
                    <FormControl sx={{ ml: 1, minWidth: 220 }} size="small">
                        <InputLabel id="aftonBluServiceLabel">Select Service</InputLabel>
                        <Select
                            labelId="aftonBluServiceLabel"
                            id="aftonBluService"
                            value={serviceId}
                            label="Select Service"
                            onChange={handleChange}
                            autoWidth
                        >
                            {ret.map((service) => (
                                <MenuItem key={service.id} value={service.id}>{service.name}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sx={{ pt: 2}}>

                    <ServiceDateTimes
                        serviceId={serviceId}
                        stylistId={stylistId}
                        handleDateChange={handleDateChange}
                        isDisabled={isDisabled}
                        disabledColor={disabledColor}
                        sliderWeeks={sliderWeeks}
                    />

                </Grid>
                <Grid item>
                    <Grid container direction="row"
                          display="flex"
                          alignItems="flex-start"
                          justifyContent="flex-start">
                            <Grid item>
                                <FadeButton
                                    onClick={() => clearButton('hide')}
                                    sx={{ color: '#000'
                                        , height: "28px"
                                        , width: "40px"
                                        , marginTop: {xxs: 1, xs: 1, sm: 2, md: 2 }
                                        , marginBottom: {xxs: 2, xs: 2, sm: 2, md: 0 }
                                        , marginLeft: 1
                                        , fontSize: 18
                                        , textTransform: 'lowercase'
                                        , backgroundColor: '#b8ddf3'
                                    }}>
                                    clear
                                </FadeButton>
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <form onSubmit={handleSubmit}>
                                        {/*onSubmit={handleSubmit}*/}
                                        <BlueUnderlinedButton
                                            type="submit"
                                            disabled={isDisabled}
                                            sx={{ height: "28px"
                                                , width: "60px"
                                                , marginTop: {xxs: 0, xs: 0, sm: 1, md: 1 }
                                                , marginBottom: {xxs: 2, xs: 2, sm: 2, md: 0 }
                                                , marginLeft: 0
                                                , fontSize: 18
                                                , textTransform: 'lowercase'
                                            }}>
                                            {buttonText}
                                        </BlueUnderlinedButton>
                                        <input type="hidden" value={JSON.stringify(selectedServiceDateTime)} id="selectedDateTime"/>
                                        <input type="hidden" value={JSON.stringify(serviceId)} id="selectedService"/>
                                        <input type="hidden" value={JSON.stringify(stylistId)} id="selectedStylist"/>
                                        <input type="hidden" value={JSON.stringify(appointmentData)} id="selectedAppointment"/>
                                    </form>
                                </FormControl>
                                <ConfirmAppointment handleConfirm={handleConfirm}
                                                    doOpenConfirm={doOpenConfirm}
                                                    serviceId={serviceId}
                                                    stylistId={stylistId}
                                                    dateTime={selectedServiceDateTime}
                                                    setIsDisabled={setIsDisabled}
                                />
                            </Grid>

                        </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>

}
